import React from 'react'
import './TextField.css'
import { Tooltip } from 'react-tooltip';
import { FaLock } from 'react-icons/fa6';

interface props {
    label?: string;
    leftLabel?: string;
    labelClass?: string;
    type?: 'text' | 'email' | 'number' | 'checkbox' | 'password' | 'date' | 'month' | 'file' | 'datetime-local'; // Define specific types for the 'type' prop
    id?: string;
    checked?: boolean;
    fieldClass?: string;
    placeholder?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value?: string;
    required?: boolean;
    min?: number | string;
    max?: number | string;
    readOnly?: boolean;
    name?: string;
    disabled?: boolean;
    ref?: React.RefObject<HTMLInputElement>;
    iconTooltip?: string;
    rightLabel?: string;
    multiple?: boolean;
    accept?: string;

}

const TextField = ({ label, leftLabel, rightLabel, labelClass, iconTooltip, type, id, checked, fieldClass, placeholder, onChange, name, required, value, min, max, readOnly, disabled, ref, multiple, accept, ...fieldProps }: props) => {
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (type === 'number' && (e.key === '+' || e.key === '-' || e.key === 'e' || e.key === 'E')) {
            e.preventDefault();
        }
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        if (type === 'number') {
            const pastedText = e.clipboardData.getData('text');
            if (/[eE+-]/.test(pastedText)) {
                e.preventDefault();
            }
        }
    };
    return (
        <>
            <div className={`${leftLabel || rightLabel ? 'd-flex  w-100 gap-2 align-items-center' : ''}`}>
                {label || leftLabel ? (
                    <label className={`form-label fw-bold text-dark fs-6 text-nowrap ${labelClass}`}>
                        {label}{leftLabel}
                        {required && <span className="required-star text-danger ms-1">*</span>}
                        {disabled && <FaLock className='fs-7 ms-1'/>}
                        {iconTooltip && (
                            <>
                                <i className="fa fa-info-circle ms-2 cursor-pointer"
                                    data-tooltip-id={`${label}-tooltip`}
                                    data-tooltip-content={iconTooltip}></i>
                                <Tooltip id={`${label}-tooltip`} place="top" />
                            </>
                        )}
                    </label>
                ) : null}

                <div className={`${type === 'checkbox' ? 'form-check form-check-sm form-check-custom form-check-solid me-3' : ''}`}>
                    <input
                        type={type}
                        className={`${type !== 'checkbox' ? 'form-control form-control-lg form-control-solid default-input' : 'form-check-input'} ${fieldClass}`}
                        placeholder={placeholder}
                        onChange={onChange}
                        name={name}
                        id={id}
                        checked={checked}
                        value={value}
                        min={min}
                        max={max}
                        onKeyDown={handleKeyDown}
                        onPaste={handlePaste}
                        readOnly={readOnly}
                        disabled={disabled}
                        ref={ref}
                        step="any"
                        multiple={multiple}
                        accept={accept}
                        {...fieldProps}
                    />
                </div>
                {rightLabel &&
                    <label className={`form-label fw-bold text-dark fs-6 text-nowrap ${labelClass}`}>
                        {rightLabel}
                        {required && <span className="required-star text-danger ms-1">*</span>}
                    </label>
                }
            </div>
        </>
    )
}

export default TextField