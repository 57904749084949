import { debounce } from 'lodash';
import moment from 'moment-timezone';
import { useCallback, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Select, { components } from 'react-select';
import { toast } from 'react-toastify';
import { PageTitle } from '../../../../_metronic/layout/core';
import CurrencyComp from '../../../../components/currencyComponent/CurrencyComp';
import DateComp from '../../../../components/dateComponent/DateComp';
import DateRangeComp from '../../../../components/dateComponent/DateRangeComp';
import { LoadingComponent } from '../../../../components/loadingComponent/LoadingComponent';
import NoRecordFound from '../../../../components/noRecord/NoRecordFound';
import Pagination from '../../../../components/pagination/Pagination';
import SearchBarComponent from '../../../../components/searchBar/SearchBarComponent';
import TableHeading from '../../../../components/tableHeading/TableHeading';
import TableSettingMenu from '../../../../components/tableHeading/TableSettingMenu';
import SearchSelect from '../../../../components/textFields/SearchSelect';
import TextField from '../../../../components/textFields/TextField';
import { constraintConfig } from '../../../../constraintConfig';
import { useGetBankFilesLineitemsV2Query } from '../../../../services/AutomationApi';
import { useGetAllCCFeedFileQuery, useGetAllUsersManagerAdminQuery } from '../../../../services/GeneralApi';
import { useGetVendorLookUpQuery } from '../../../../services/VendorApi';
import useColumnVisibility from '../../../customHooks/useColumnVisibility';
import labelKey from '../../../localization/label.json';
import BankCCFeedDetailViewModal from './BankCCFeedDetailViewModal';
import { IoIosRepeat } from 'react-icons/io';
import { Tooltip } from 'react-tooltip';

const { Option } = components;
type Option = {
    value: string;
    label: string;
};

// Custom Option component to add checkboxes
const CheckboxOption = (props: any) => {
    return (
        <Option {...props} className='d-flex align-items-start gap-2'>
            <input
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}
            />{" "}
            <label>{props.label}</label>
        </Option>
    );
};

const UserCCFeeds = () => {
    const { hiddenColumns, handleToggleColumn } = useColumnVisibility({ pageName: 'userCCFeed' });

    const currentDate = moment().format('MM/DD/YYYY');
    const threeDaysAgo = moment().subtract(3, 'days').format('MM/DD/YYYY');
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(1000);
    const [searchStr, setSearchStr] = useState(''); // debounce search
    const [immediateSearchStr, setImmediateSearchStr] = useState('');
    const [trackingIdSearchStr, setTrackingIdSearchStr] = useState(''); // debounce search
    const [immediateTrackingIdSearchStr, setImmediateTrackingIdSearchStr] = useState('');
    const [accountNoSearchStr, setAccountNoSearchStr] = useState(''); // debounce search
    const [immediateAccountNoSearchStr, setImmediateAccountNoSearchStr] = useState('');
    const [filterByBCEmployeeId, setFilterByBCEmployeeId] = useState(''); // debounce search
    const [localFilterByBCEmployeeId, setLocalFilterByBCEmployeeId] = useState('');
    const [filterByAmount, setFilterByAmount] = useState(''); // debounce search
    const [localFilterByAmount, setLocalFilterByAmount] = useState('');
    const [filterByDescription, setFilterByDescription] = useState(''); // debounce search
    const [localFilterByDescription, setLocalFilterByDescription] = useState('');
    const [sortBy, setSortBy] = useState("itemImportDate");
    const [sortOrder, setSortOrder] = useState("desc");
    const [sortedColumn, setSortedColumn] = useState("itemImportDate");
    const [filterByStartDate, setFilterByStartDate] = useState(threeDaysAgo);
    const [filterByEndDate, setFilterByEndDate] = useState(currentDate);
    const [filterByTimePeriod, setFilterByTimePeriod] = useState('');
    const [filterByUserIds, setFilterByUserIds] = useState<string[]>([]);
    const [filterByVendor, setFilterByVendor] = useState("");
    const [localFilterByVendor, setLocalFilterByVendor] = useState("");
    const [selectedUserIds, setSelectedUserIds] = useState<Option[]>([]);
    const [localFilterByStartDate, setLocalFilterByStartDate] = useState(threeDaysAgo);
    const [localFilterByEndDate, setLocalFilterByEndDate] = useState(currentDate);
    const [localFilterByTimePeriod, setLocalFilterByTimePeriod] = useState("");
    const [localFilterByUserIds, setLocalFilterByUserIds] = useState<string[]>([]);
    const [filterByFile, setFilterByFile] = useState("");
    const [localFilterByFile, setLocalFilterByFile] = useState("");
    const [activeTab, setActiveTab] = useState(true);
    const [filterByStatus, setFilterByStatus] = useState(true);
    const [isLoading, setIsLoading] = useState(false); // Track loading state

    const [isApplyingFilters, setIsApplyingFilters] = useState(false);

    // Debounced search state
    // const [searchStr, setSearchStr] = useState('');
    const { data: getUserByManager } = useGetAllUsersManagerAdminQuery('')
    const { data: vendor, refetch: vendorRefetch } = useGetVendorLookUpQuery('')
    const { data: ccFeedFileLookup } = useGetAllCCFeedFileQuery('')


    const { data, refetch, isSuccess } = useGetBankFilesLineitemsV2Query({
        pageNumber: pageNumber,
        pageSize: pageSize,
        searchStr: searchStr,
        trackingId: trackingIdSearchStr,
        accountNo: accountNoSearchStr,
        vendorId: filterByVendor,
        sortBy: sortBy,
        sortOrder: sortOrder,
        startDate: filterByStartDate,
        endDate: filterByEndDate,
        timePeriod: filterByTimePeriod,
        userIds: filterByUserIds.length > 0 ? filterByUserIds : null,
        bcEmployeeId: filterByBCEmployeeId,
        amount: filterByAmount,
        description: filterByDescription,
        fileId: filterByFile,
        IslinkedItems: filterByStatus
    })
    useEffect(() => {
        setIsLoading(true); // Set loading to true when filter changes
        refetch().finally(() => setIsLoading(false)); // Finally, set loading to false when refetch is done
    }, [filterByStatus, pageNumber, pageSize, refetch]);
    const handlePageSizeChange = (e: any) => {
        const newSize = parseInt(e.target.value);
        setPageSize(newSize);
        setPageNumber(1); // Reset page number to 1
    };
    // pagination
    const handlePageClick = (e: any) => {
        setPageNumber(e.selected + 1);
    };
    //per page record
    const handlePageRecords = (e: any) => {
        setPageNumber(1); // Reset the page to 1
    };
    const handleSort = (property: string) => {
        setSortBy(property);
        if (sortedColumn === property) {
            setSortOrder(prevSortOrder => (prevSortOrder === "asc" ? "desc" : "asc"));
        } else {
            setSortedColumn(property);
            setSortOrder("asc");
        }
    };

    const handleSearchChange = (e: any) => {
        const { value } = e.target;
        setImmediateSearchStr(value); // Update immediate search state

        delayedSearch(value); // Update debounced search state
        setPageNumber(1)
    };
    const delayedSearch = useCallback(
        debounce((searchValue) => {
            setSearchStr(searchValue);
        }, 500),
        []
    );
    const handleTrackingIdSearchChange = (e: any) => {
        const { value } = e.target;
        setImmediateTrackingIdSearchStr(value);
    };

    const handleAccountNoSearchChange = (e: any) => {
        const { value } = e.target;
        setImmediateAccountNoSearchStr(value);
    };

    const handleBcEmployeeId = (e: any) => {
        const { value } = e.target;
        setLocalFilterByBCEmployeeId(value);
    };
    const handleFilterByAmount = (e: any) => {
        const { value } = e.target;
        setLocalFilterByAmount(value);
    };
    const handleFilterByDescription = (e: any) => {
        const { value } = e.target;
        setLocalFilterByDescription(value);
    };

    useEffect(() => {
        if (searchStr !== "" && sortOrder !== "" && sortBy !== "" && trackingIdSearchStr !== "") {
            // Trigger the API call after the debounce time
            refetch();
        }
    }, [searchStr, sortOrder, sortBy, refetch]);
    // const [hiddenColumns, setHiddenColumns] = useState<string[]>(['sr', 'user', 'fileName', 'totalAmountOfDebits', 'totalParsedAmount', 'itemImportDate', 'ccTotalAmount', 'itemDescription', 'totalLineItemCreatedAmount', 'parsingStatus', 'accountNumber', 'bankVendorName', 'actions', 'expenseReportId', 'bcEmployeeId', 'expenseCategory']);
    // const handleToggleColumn = (columnId: string) => {
    //     if (hiddenColumns.includes(columnId)) {
    //         setHiddenColumns(hiddenColumns.filter((id) => id !== columnId));
    //     } else {
    //         setHiddenColumns([...hiddenColumns, columnId]);
    //     }
    // };

    const [showBankFeedDetailModal, setShowBankFeedDetailModal] = useState(false)
    const [userBankFeedData, setUserBankFeedData] = useState();
    const [bankFeedData, setBankFeedData] = useState();

    const bankFeedDetailHandler = () => {
        setShowBankFeedDetailModal((prev) => !prev);
    };
    useEffect(() => {
        if (showBankFeedDetailModal === false) {
            refetch()
        }
    }, [showBankFeedDetailModal])
    const handleRefresh = () => {
        // Set the state values
        // setFilterByStartDate(threeDaysAgo);
        // setFilterByEndDate(currentDate);
        // setLocalFilterByStartDate(threeDaysAgo);
        // setLocalFilterByEndDate(currentDate);

        // Trigger refetch after state updates are done
        refetch()
            .then(() => {
                if (isSuccess) {
                    toast.success('Data Refresh successfully!');
                }
            });
    };

    const handleDateRangeChange = (event: any, picker: any) => {
        const startDateFormatted = moment(picker.startDate).format('MM-DD-YYYY');
        const endDateFormatted = moment(picker.endDate).format('MM-DD-YYYY');

        setLocalFilterByStartDate(startDateFormatted);
        setLocalFilterByEndDate(endDateFormatted);
        setLocalFilterByTimePeriod('')
    };
    const handleCancelDateSelection = (event: any, picker: any) => {
        // Reset the state to empty strings
        setFilterByStartDate("");
        setFilterByEndDate("");
        setLocalFilterByStartDate("");
        setLocalFilterByEndDate("");
    };
    const userIdsOptions: Option[] = getUserByManager?.result?.map((userByManager: any) => ({
        value: userByManager.id,
        label: userByManager.value,
    }));
    const handleUserIdsChange = (selected: Option[]) => {
        setSelectedUserIds(selected);
        setLocalFilterByUserIds(selected?.map((userByManager) => userByManager.value));
    };
    const handleTimePeriodChange = (selectedOption: any) => {
        setLocalFilterByTimePeriod(selectedOption.value);
        setLocalFilterByStartDate('');
        setLocalFilterByEndDate('');
    };
    const handleVendorChange = (e: any) => {
        setLocalFilterByVendor(e.value);
    };
    const handleFilterByFile = (e: any) => {
        setLocalFilterByFile(e.value);
    };
    const applyFilters = () => {
        setIsApplyingFilters(true);
        setFilterByStartDate(localFilterByStartDate);
        setFilterByEndDate(localFilterByEndDate);
        setFilterByUserIds(localFilterByUserIds)
        setFilterByTimePeriod(localFilterByTimePeriod)
        setTrackingIdSearchStr(immediateTrackingIdSearchStr)
        setAccountNoSearchStr(immediateAccountNoSearchStr)
        setFilterByVendor(localFilterByVendor)
        setFilterByBCEmployeeId(localFilterByBCEmployeeId)
        setFilterByAmount(localFilterByAmount)
        setFilterByDescription(localFilterByDescription)
        setFilterByFile(localFilterByFile)
        setPageNumber(1);

    };
    const resetFilters = () => {
        setFilterByStartDate('');
        setFilterByEndDate('');
        setFilterByUserIds([])
        setFilterByTimePeriod('')
        setTrackingIdSearchStr('')
        setAccountNoSearchStr('')
        setFilterByVendor('')
        setFilterByBCEmployeeId('')
        setFilterByAmount('')
        setFilterByDescription('')
        setFilterByFile('')

        setLocalFilterByStartDate('');
        setLocalFilterByEndDate('');
        setLocalFilterByUserIds([])
        setLocalFilterByTimePeriod('')
        setLocalFilterByVendor('')
        setImmediateAccountNoSearchStr('')
        setImmediateTrackingIdSearchStr('')
        setLocalFilterByBCEmployeeId('')
        setLocalFilterByAmount('')
        setLocalFilterByDescription('')
        setLocalFilterByFile('')
        setSelectedUserIds([])
    }

    const debouncedRefetch = debounce(() => {
        refetch().finally(() => {
            setIsApplyingFilters(false);
        });
    }, 100);
    useEffect(() => {
        if (isApplyingFilters) {
            debouncedRefetch();
        }
    }, [isApplyingFilters]);

    const handleTabChange = (status: boolean) => {
        setActiveTab(status);
        setFilterByStatus(status);
        setTimeout(() => {
            refetch();
        }, 50);
        setPageNumber(1)
        setPageSize(1000)
    };

    return (
        <>
            <BankCCFeedDetailViewModal
                show={showBankFeedDetailModal}
                handleClose={() => setShowBankFeedDetailModal(false)}
                // userBankFeedData={userBankFeedData}
                bankFeedData={bankFeedData}
            />
            <PageTitle breadcrumbs={[]}>Automation - CC Feeds - LineItems</PageTitle>
            <Tooltip id="refreshData" place="top" />

            <div className='card mb-5'>
                {/* <div className="card-header  border-0 pt-6">
                    <div className='d-flex align-items-center gap-2'>
                        <div className='btn-group overflow-auto' style={{ minWidth: "max-content", height: 'fit-content' }}>
                            <button
                                type='button'
                                className={`btn btn-sm ${activeTab === true ? 'btn-primary' : 'btn-light-primary'}`}
                                onClick={() => handleTabChange(true)}
                            >
                                {labelKey.lineItems}
                            </button>
                            <button
                                type='button'
                                className={`btn btn-sm ${activeTab === false ? 'btn-primary' : 'btn-light-primary'}`}
                                onClick={() => handleTabChange(false)}
                            >
                                {labelKey.lineItemsPending}
                            </button>
                        </div>
                        <div
                            data-tooltip-id="refreshData" data-tooltip-content='Refresh Data'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-2' onClick={handleRefresh}>
                            <IoIosRepeat className='h-20px w-20px' />
                        </div>
                    </div>
                </div> */}
                <div className='card-body pb-8 pt-5'>
                    <div className="row align-items-end">
                        {/* <div className="col-md-4 col-lg-3 col-xl-2 mb-5">
                            <SearchBarComponent
                                placeholder='File Parsing'
                                value={immediateSearchStr}
                                onChange={handleSearchChange}
                            />
                        </div> */}
                        <div className='col-md-4 col-lg-3 col-xl-2 mb-5'>
                            <SearchSelect
                                label={`${labelKey.ccFeedFile}:`}
                                options={[
                                    // { value: '', label: 'Select File' }, // Add the empty option
                                    ...(Array.isArray(ccFeedFileLookup?.result) ? ccFeedFileLookup.result.map((option: any) => ({
                                        value: option.id,
                                        label: option?.value,
                                    })) : [])
                                ]}
                                placeholder={labelKey.selectFile}
                                value={localFilterByFile && localFilterByFile !== ''
                                    ? {
                                        value: localFilterByFile,
                                        label: ccFeedFileLookup?.result.find((option: any) => option.id === localFilterByFile)?.value
                                    }
                                    : null
                                }
                                onChange={handleFilterByFile}
                            />
                        </div>
                        <div className='col-md-4 col-lg-3 col-xl-2 mb-5'>
                            <label className='form-label fs-6 fw-bold'>{labelKey.users}:</label>
                            <Select
                                isMulti
                                options={userIdsOptions}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{ Option: CheckboxOption }}
                                onChange={(selected) => handleUserIdsChange(selected as Option[])} // Casting to Option[]
                                value={selectedUserIds} // Directly use selectedDeptId as it is of type Option[]
                                placeholder='Search User...'
                                className='filter-valueContainer'
                            />
                        </div>
                        <div className="col-md-4 col-lg-3 col-xl-2 mb-5">
                            <label className='form-label fs-6 fw-bold'>{labelKey.reportId}:</label>
                            <SearchBarComponent
                                placeholder='Tracking ID'
                                value={immediateTrackingIdSearchStr}
                                onChange={handleTrackingIdSearchChange}
                                className='w-100'
                            />
                        </div>
                        <div className="col-md-4 col-lg-3 col-xl-2 mb-5">
                            <label className='form-label fs-6 fw-bold'>{labelKey.bcEmployeeId}:</label>
                            <SearchBarComponent
                                placeholder='BC Employee ID'
                                value={localFilterByBCEmployeeId}
                                onChange={handleBcEmployeeId}
                                className='w-100'
                            />
                        </div>
                        <div className="col-md-4 col-lg-3 col-xl-2 mb-5">
                            <label className='form-label fs-6 fw-bold'>{labelKey.accountNumber}:</label>
                            <SearchBarComponent
                                placeholder='Account No'
                                value={immediateAccountNoSearchStr}
                                onChange={handleAccountNoSearchChange}
                                className='w-100'
                            />
                        </div>
                        <div className='col-md-4 col-lg-3 col-xl-2 mb-5'>
                            <SearchSelect
                                label={`${labelKey.vendor}:`}
                                options={[
                                    // { value: '', label: 'Select Vendor' }, // Add the empty option
                                    ...(Array.isArray(vendor?.result) ? vendor.result.map((option: any) => ({
                                        value: option.id,
                                        label: option?.value,
                                    })) : [])
                                ]}
                                placeholder={labelKey.selectVendor}
                                value={localFilterByVendor && localFilterByVendor !== ''
                                    ? {
                                        value: localFilterByVendor,
                                        label: vendor?.result.find((option: any) => option.id === localFilterByVendor)?.value
                                    }
                                    : null
                                }
                                onChange={handleVendorChange}
                            />
                        </div>
                        <div className="col-md-4 col-lg-3 col-xl-2 mb-5">
                            <label className='form-label fs-6 fw-bold'>{labelKey.amount}:</label>
                            <SearchBarComponent
                                placeholder='Amount'
                                value={localFilterByAmount}
                                onChange={handleFilterByAmount}
                                className='w-100'
                            />
                        </div>
                        <div className="col-md-4 col-lg-3 col-xl-2 mb-5">
                            <label className='form-label fs-6 fw-bold'>{labelKey.description}:</label>
                            <SearchBarComponent
                                placeholder={labelKey.description}
                                value={localFilterByDescription}
                                onChange={handleFilterByDescription}
                                className='w-100'
                            />
                        </div>

                        <div className='col-md-4 col-lg-3 col-xl-2 mb-5'>
                            <DateRangeComp
                                label="Item Date Range"
                                startDate={localFilterByStartDate || moment()}
                                endDate={localFilterByEndDate || moment()}
                                onApply={handleDateRangeChange} onCancel={handleCancelDateSelection}
                                value={localFilterByStartDate && localFilterByEndDate ? `${localFilterByStartDate} - ${localFilterByEndDate}` : "MM/DD/YYYY"} />
                        </div>
                        <div className='col-md-4 col-lg-3 col-xl-2 mb-5'>
                            <div className='d-flex align-items-end h-100 gap-3'>
                                <button className='btn btn-danger pull-right me-3' onClick={resetFilters}>Reset Filters</button>

                                <button className='btn btn-primary pull-right' onClick={applyFilters}>
                                    {isApplyingFilters ? <>
                                        <span>loading...</span>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    </> : "Apply Filters"}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-md-12">
                            <div className='d-flex align-items-center gap-2'>
                                <div className='btn-group overflow-auto' style={{ minWidth: "max-content", height: 'fit-content' }}>
                                    <button
                                        type='button'
                                        className={`btn btn-sm ${activeTab === true ? 'btn-primary' : 'btn-light-primary'}`}
                                        onClick={() => handleTabChange(true)}
                                    >
                                        {labelKey.lineItems}
                                    </button>
                                    <button
                                        type='button'
                                        className={`btn btn-sm ${activeTab === false ? 'btn-primary' : 'btn-light-primary'}`}
                                        onClick={() => handleTabChange(false)}
                                    >
                                        {labelKey.lineItemsPending}
                                    </button>
                                </div>
                                <div
                                    data-tooltip-id="refreshData" data-tooltip-content='Refresh Data'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-2' onClick={handleRefresh}>
                                    <IoIosRepeat className='h-20px w-20px' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className='table-responsive table-height'>
                            {/* <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                <thead>
                                    <tr className='fw-bold text-muted'>
                                        {hiddenColumns.includes('sr') && (
                                            <TableHeading
                                                label={labelKey.sr}
                                                columnId='sr'
                                                className="w-60px ps-2"
                                            />
                                        )}
                                        {hiddenColumns.includes('user') && (
                                            <TableHeading
                                                label={labelKey.user}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='user'
                                            />
                                        )}
                                        {hiddenColumns.includes('fileName') && (
                                            <TableHeading
                                                label={labelKey.fileName}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='fileName'
                                                className="w-400px"
                                            />
                                        )}
                                        {hiddenColumns.includes('bcEmployeeId') && (
                                            <TableHeading
                                                label={labelKey.bcEmployeeId}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='bcEmployeeId'
                                                className="w-400px"
                                            />
                                        )}
                                        {hiddenColumns.includes('expenseReportId') && (
                                            <TableHeading
                                                label={labelKey.reportId}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='expenseReportId'
                                                className="w-400px"
                                            />
                                        )}
                                        {hiddenColumns.includes('expenseCategory') && (
                                            <TableHeading
                                                label={labelKey.category}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='expenseCategory'
                                                className="w-400px"
                                            />
                                        )}
                                        {hiddenColumns.includes('parsingStatus') && (
                                            <TableHeading
                                                label={labelKey.importStatus}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='parsingStatus'
                                            />
                                        )}
                                        {hiddenColumns.includes('accountNumber') && (
                                            <TableHeading
                                                label={labelKey.accountNumber}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='accountNumber'
                                            />
                                        )}
                                        {hiddenColumns.includes('bankVendorName') && (
                                            <TableHeading
                                                label={labelKey.vendor}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='bankVendorName'
                                            />
                                        )}
                                        {hiddenColumns.includes('itemImportDate') && (
                                            <TableHeading
                                                label={labelKey.itemImportDate}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='itemImportDate'
                                                className="w-100px"
                                            />
                                        )}
                                        {hiddenColumns.includes('ccTotalAmount') && (
                                            <TableHeading
                                                label={labelKey.ccTotalAmount}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='ccTotalAmount'
                                                className="w-100px"
                                            />
                                        )}
                                        {hiddenColumns.includes('itemDescription') && (
                                            <TableHeading
                                                label={labelKey.itemDescription}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='itemDescription'
                                                className="w-100px"
                                            />
                                        )}
                                        <th className='text-end rounded-end pe-2 border-0'>
                                            {hiddenColumns.includes('actions') && (
                                                <>
                                                    {labelKey.actions}
                                                </>
                                            )}
                                            <TableSettingMenu>
                                                <TextField
                                                    rightLabel={labelKey.sr}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("sr")}
                                                    checked={hiddenColumns.includes('sr')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.user}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("user")}
                                                    checked={hiddenColumns.includes('user')}
                                                    fieldClass='mb-4'
                                                />

                                                <TextField
                                                    rightLabel={labelKey.fileName}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("fileName")}
                                                    checked={hiddenColumns.includes('fileName')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.bcEmployeeId}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("bcEmployeeId")}
                                                    checked={hiddenColumns.includes('bcEmployeeId')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.reportId}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("expenseReportId")}
                                                    checked={hiddenColumns.includes('expenseReportId')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.category}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("expenseCategory")}
                                                    checked={hiddenColumns.includes('expenseCategory')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.importStatus}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("parsingStatus")}
                                                    checked={hiddenColumns.includes('parsingStatus')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.accountNumber}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("accountNumber")}
                                                    checked={hiddenColumns.includes('accountNumber')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.vendor}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("bankVendorName")}
                                                    checked={hiddenColumns.includes('bankVendorName')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.itemImportDate}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("itemImportDate")}
                                                    checked={hiddenColumns.includes('itemImportDate')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.ccTotalAmount}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("ccTotalAmount")}
                                                    checked={hiddenColumns.includes('ccTotalAmount')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.itemDescription}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("itemDescription")}
                                                    checked={hiddenColumns.includes('itemDescription')}
                                                    fieldClass='mb-4'
                                                />
                                            </TableSettingMenu>
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {!isLoading &&
                                        <>
                                            {data?.result?.data?.length > 0 ? (
                                                <>
                                                    {data?.result?.data?.map((data: any, index: any) => (
                                                        <tr key={index}>
                                                            {hiddenColumns.includes('sr') && (
                                                                <td className='ps-4'>
                                                                    {(pageNumber - 1) * pageSize + index + 1}
                                                                </td>
                                                            )}
                                                            {hiddenColumns.includes('user') && (
                                                                <td>{data?.userFullName}</td>
                                                            )}
                                                            {hiddenColumns.includes('fileName') && (
                                                                <td>{data?.fileName}</td>
                                                            )}
                                                            {hiddenColumns.includes('bcEmployeeId') && (
                                                                <td>{data?.bcEmployeeId}</td>
                                                            )}
                                                            {hiddenColumns.includes('expenseReportId') && (
                                                                <td>{data?.expenseReportId}</td>
                                                            )}
                                                            {hiddenColumns.includes('expenseCategory') && (
                                                                <td>{data?.expenseCategory?.title}</td>
                                                            )}
                                                            {hiddenColumns.includes('parsingStatus') && (
                                                                <td className='ps-11'> {data?.parsingStatus === true ?
                                                                    <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.parsed}</span> :
                                                                    <span className="badge badge-light-danger fs-7 fw-semibold">{labelKey.failed}</span>}
                                                                </td>
                                                            )}
                                                            {hiddenColumns.includes('accountNumber') && (
                                                                <td>{data?.accountNumber}</td>
                                                            )}
                                                            {hiddenColumns.includes('bankVendorName') && (
                                                                <td>{data?.bankVendorName}</td>
                                                            )}

                                                            {hiddenColumns.includes('itemImportDate') && (
                                                                <td className='text-end'><DateTimeComp formattedDate={data?.itemImportDate} /></td>
                                                            )}
                                                            {hiddenColumns.includes('ccTotalAmount') && (
                                                                <td className='text-end'><CurrencyComp amount={data?.ccTotalAmount} /></td>
                                                            )}
                                                            {hiddenColumns.includes('itemDescription') && (
                                                                <td colSpan={2}>{data?.itemDescription}</td>
                                                            )}
                                                            <td>
                                                                {hiddenColumns.includes('actions') && (
                                                                    <p className='text-end pe-9 text-primary mb-0'
                                                                        onClick={() => {
                                                                            setUserBankFeedData(data);
                                                                            bankFeedDetailHandler();
                                                                        }}>
                                                                        View Detail
                                                                    </p>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </>) : (
                                                <tr>
                                                    <td colSpan={20}>
                                                        <NoRecordFound />
                                                    </td>
                                                </tr>
                                            )}
                                        </>}
                                    {isLoading && (
                                        <tr>
                                            <td colSpan={20} className="text-center">
                                                <LoadingComponent />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table> */}
                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                <thead>
                                    <tr className='fw-bold text-muted'>

                                        <>
                                            {hiddenColumns.includes('cCTransactionID') && (
                                                <TableHeading
                                                    label={labelKey.cCTransactionID}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='cCTransactionID'
                                                    className="ps-4"
                                                />
                                            )}

                                            {hiddenColumns.includes('accountNumber') && (
                                                <TableHeading
                                                    label={labelKey.accountNumber}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='accountNumber'
                                                    className="ps-4"
                                                />
                                            )}
                                            {hiddenColumns.includes('fileName') && (
                                                <TableHeading
                                                    label={labelKey.fileName}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='fileName'
                                                    className="ps-4"
                                                />
                                            )}
                                            {hiddenColumns.includes('merchantType') && (
                                                <TableHeading
                                                    label={labelKey.merchantType}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='merchantType'
                                                    className="ps-4"
                                                />
                                            )}

                                            {/* {hiddenColumns.includes('productCode') && (
                                                <TableHeading
                                                    label={labelKey.productCode}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='productCode'
                                                />
                                            )} */}
                                            {hiddenColumns.includes('itemDescription') && (
                                                <TableHeading
                                                    label={labelKey.itemDescription}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='itemDescription'
                                                />
                                            )}
                                            {hiddenColumns.includes('itemImportDate') && (
                                                <TableHeading
                                                    label={labelKey.itemImportDate}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='itemImportDate'
                                                    className="w-100px text-end pe-15"
                                                />
                                            )}
                                            {hiddenColumns.includes('transactionDate') && (
                                                <TableHeading
                                                    label={labelKey.transactionDate}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='transactionDate'
                                                    className="w-100px text-end"
                                                />
                                            )}
                                            {hiddenColumns.includes('bankVendorName') && (
                                                <TableHeading
                                                    label={labelKey.vendor}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='bankVendorName'
                                                />
                                            )}
                                            {hiddenColumns.includes('debitOrCreditIndicator') && (
                                                <TableHeading
                                                    label={labelKey.indicator}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='debitOrCreditIndicator'
                                                    className="w-100px text-end"
                                                />
                                            )}
                                            {hiddenColumns.includes('ccTotalAmount') && (
                                                <TableHeading
                                                    label={labelKey.ccTotalAmount}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='ccTotalAmount pe-4'
                                                    labelClassName="justify-content-end"
                                                />
                                            )}
                                        </>
                                        {activeTab === true &&
                                            <>
                                                {/* {hiddenColumns.includes('lineItemDetailEntityId') && (
                                                    <TableHeading
                                                        label={labelKey.lineItemDetailEntityId}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='lineItemDetailEntityId'
                                                        className="ps-4"
                                                        labelClassName="text-primary"
                                                    />
                                                )} */}
                                                {hiddenColumns.includes('ssLineItemId') && (
                                                    <TableHeading
                                                        label={labelKey.ssLineItemId}
                                                        columnId='ssLineItemId'
                                                        className="ps-4"
                                                        labelClassName="text-primary"
                                                    />
                                                )}
                                                {hiddenColumns.includes('employeeId') && (
                                                    <TableHeading
                                                        label={labelKey.employeeId}
                                                        columnId='employeeId'
                                                        className="ps-4"
                                                        labelClassName="text-primary"
                                                    />
                                                )}
                                                {hiddenColumns.includes('userFullName') && (
                                                    <TableHeading
                                                        label={labelKey.userName}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='userFullName'
                                                        labelClassName="text-primary"
                                                    />
                                                )}
                                                {hiddenColumns.includes('expenseReportId') && (
                                                    <TableHeading
                                                        label={labelKey.reportId}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='expenseReportId'
                                                        labelClassName="text-primary"
                                                    />
                                                )}
                                                {hiddenColumns.includes('expenseCategoryName') && (
                                                    <TableHeading
                                                        label={labelKey.category}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='expenseCategoryName'
                                                        labelClassName="text-primary"
                                                    />
                                                )}
                                                {hiddenColumns.includes('vendorName') && (
                                                    <TableHeading
                                                        label={labelKey.vendor}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='vendorName'
                                                        labelClassName="text-primary"
                                                    />
                                                )}
                                                {hiddenColumns.includes('itemDate') && (
                                                    <TableHeading
                                                        label={labelKey.itemDate}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='itemDate'
                                                        labelClassName="text-primary"
                                                    />
                                                )}
                                                {hiddenColumns.includes('currency') && (
                                                    <TableHeading
                                                        label={labelKey.currency}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='currency'
                                                        labelClassName="text-primary"
                                                    />
                                                )}
                                                {hiddenColumns.includes('itemDebitOrCreditIndicator') && (
                                                    <TableHeading
                                                        label={labelKey.indicator}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='itemDebitOrCreditIndicator'
                                                        labelClassName="text-primary"
                                                    />
                                                )}
                                                {hiddenColumns.includes('itemAmount') && (
                                                    <TableHeading
                                                        label={labelKey.itemAmount}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='itemAmount'
                                                        className="pe-4"
                                                        labelClassName="justify-content-end text-primary"
                                                    />
                                                )}
                                            </>
                                        }


                                        <th className='text-end rounded-end pe-2'>
                                            <TableSettingMenu>
                                                <TextField
                                                    rightLabel={labelKey.cCTransactionID}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("cCTransactionID")}
                                                    checked={hiddenColumns.includes('cCTransactionID')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.accountNumber}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("accountNumber")}
                                                    checked={hiddenColumns.includes('accountNumber')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.fileName}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("fileName")}
                                                    checked={hiddenColumns.includes('fileName')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.merchantType}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("merchantType")}
                                                    checked={hiddenColumns.includes('merchantType')}
                                                    fieldClass='mb-4'
                                                />

                                                {/* <TextField
                                                    rightLabel={labelKey.productCode}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("productCode")}
                                                    checked={hiddenColumns.includes('productCode')}
                                                    fieldClass='mb-4'
                                                /> */}
                                                <TextField
                                                    rightLabel={labelKey.itemDescription}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("itemDescription")}
                                                    checked={hiddenColumns.includes('itemDescription')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.itemImportDate}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("itemImportDate")}
                                                    checked={hiddenColumns.includes('itemImportDate')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.transactionDate}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("transactionDate")}
                                                    checked={hiddenColumns.includes('transactionDate')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.vendor}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("bankVendorName")}
                                                    checked={hiddenColumns.includes('bankVendorName')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.indicator}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("debitOrCreditIndicator")}
                                                    checked={hiddenColumns.includes('debitOrCreditIndicator')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.ccTotalAmount}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("ccTotalAmount")}
                                                    checked={hiddenColumns.includes('ccTotalAmount')}
                                                    fieldClass='mb-4'
                                                />
                                                {activeTab === true &&
                                                    <>
                                                        <TextField
                                                            rightLabel={labelKey.ssLineItemId}
                                                            type="checkbox"
                                                            onChange={() => handleToggleColumn("ssLineItemId")}
                                                            checked={hiddenColumns.includes('ssLineItemId')}
                                                            fieldClass='mb-4'
                                                        />
                                                        <TextField
                                                            rightLabel={labelKey.employeeId}
                                                            type="checkbox"
                                                            onChange={() => handleToggleColumn("employeeId")}
                                                            checked={hiddenColumns.includes('employeeId')}
                                                            fieldClass='mb-4'
                                                        />
                                                        <TextField
                                                            rightLabel={labelKey.userName}
                                                            type="checkbox"
                                                            onChange={() => handleToggleColumn("userFullName")}
                                                            checked={hiddenColumns.includes('userFullName')}
                                                            fieldClass='mb-4'
                                                        />
                                                        <TextField
                                                            rightLabel={labelKey.reportId}
                                                            type="checkbox"
                                                            onChange={() => handleToggleColumn("expenseReportId")}
                                                            checked={hiddenColumns.includes('expenseReportId')}
                                                            fieldClass='mb-4'
                                                        />
                                                        <TextField
                                                            rightLabel={labelKey.category}
                                                            type="checkbox"
                                                            onChange={() => handleToggleColumn("expenseCategoryName")}
                                                            checked={hiddenColumns.includes('expenseCategoryName')}
                                                            fieldClass='mb-4'
                                                        />
                                                        <TextField
                                                            rightLabel={labelKey.vendor}
                                                            type="checkbox"
                                                            onChange={() => handleToggleColumn("vendorName")}
                                                            checked={hiddenColumns.includes('vendorName')}
                                                            fieldClass='mb-4'
                                                        />
                                                        <TextField
                                                            rightLabel={labelKey.itemDate}
                                                            type="checkbox"
                                                            onChange={() => handleToggleColumn("itemDate")}
                                                            checked={hiddenColumns.includes('itemDate')}
                                                            fieldClass='mb-4'
                                                        />
                                                        <TextField
                                                            rightLabel={labelKey.currency}
                                                            type="checkbox"
                                                            onChange={() => handleToggleColumn("currency")}
                                                            checked={hiddenColumns.includes('currency')}
                                                            fieldClass='mb-4'
                                                        />
                                                        <TextField
                                                            rightLabel={labelKey.indicator}
                                                            type="checkbox"
                                                            onChange={() => handleToggleColumn("itemDebitOrCreditIndicator")}
                                                            checked={hiddenColumns.includes('itemDebitOrCreditIndicator')}
                                                            fieldClass='mb-4'
                                                        />

                                                        <TextField
                                                            rightLabel={labelKey.itemAmount}
                                                            type="checkbox"
                                                            onChange={() => handleToggleColumn("itemAmount")}
                                                            checked={hiddenColumns.includes('itemAmount')}
                                                            fieldClass='mb-4'
                                                        />
                                                    </>}
                                            </TableSettingMenu>
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>

                                    {!isLoading &&
                                        <>
                                            {data?.result?.data?.length > 0 ? (
                                                <>
                                                    {data?.result?.data?.map((data: any, index: any) => (
                                                        <tr key={index}>
                                                            {hiddenColumns.includes('cCTransactionID') && (
                                                                <td className='ps-4'>{data?.processorTransactionId ? data?.processorTransactionId : "N/A"}</td>
                                                            )}
                                                            {hiddenColumns.includes('accountNumber') && (
                                                                <td>{data?.accountNumber ? data?.accountNumber : "N/A"}</td>
                                                            )}
                                                            {hiddenColumns.includes('fileName') && (
                                                                <td>
                                                                    <span className='text-primary'
                                                                        onClick={() => {
                                                                            setBankFeedData(data);
                                                                            bankFeedDetailHandler();
                                                                        }}>
                                                                        {data?.fileName ? data?.fileName : "N/A"}
                                                                    </span>
                                                                </td>
                                                            )}
                                                            {hiddenColumns.includes('merchantType') && (
                                                                <td>{data?.merchantType ? data?.merchantType : "N/A"}</td>
                                                            )}
                                                            {/* {hiddenColumns.includes('productCode') && (
                                                                <td>
                                                                    {data?.productCode}
                                                                </td>
                                                            )} */}
                                                            {hiddenColumns.includes('itemDescription') && (
                                                                <td>
                                                                    <div className='w-200px text-wrap'>
                                                                        {data?.itemDescription ? data?.itemDescription : "N/A"}
                                                                    </div>
                                                                </td>
                                                            )}
                                                            {hiddenColumns.includes('itemImportDate') && (
                                                                <td className='text-end pe-15'><DateComp formattedDate={data?.itemImportDate} /> </td>
                                                            )}
                                                            {hiddenColumns.includes('transactionDate') && (
                                                                <td className='text-end'><DateComp formattedDate={data?.transactionDate} /></td>
                                                            )}
                                                            {hiddenColumns.includes('bankVendorName') && (
                                                                <td>{data?.bankVendorName}</td>
                                                            )}
                                                            {hiddenColumns.includes('debitOrCreditIndicator') && (
                                                                <td className={`text-center ${data?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator ? 'text-danger' : null}`}>{data?.debitOrCreditIndicator}</td>
                                                            )}
                                                            {hiddenColumns.includes('ccTotalAmount') && (
                                                                <td className={`text-end pe-4 ${data?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator ? 'text-danger' : null}`}>
                                                                    <CurrencyComp amount={data?.ccTotalAmount} />
                                                                </td>
                                                            )}
                                                            {activeTab === true &&
                                                                <>
                                                                    {/* {hiddenColumns.includes('lineItemDetailEntityId') && (
                                                                        <td>{data?.lineItemDetailEntityId ? data?.lineItemDetailEntityId : "N/A"}</td>
                                                                    )} */}
                                                                    {hiddenColumns.includes('ssLineItemId') && (
                                                                        <td className='ps-4'>{data?.ccLineItemId}</td>
                                                                    )}
                                                                    {hiddenColumns.includes('employeeId') && (
                                                                        <td className='ps-4'>{data?.employeeId}</td>
                                                                    )}
                                                                    {hiddenColumns.includes('userFullName') && (
                                                                        <td>
                                                                            {data?.userFullName}
                                                                        </td>
                                                                    )}
                                                                    {hiddenColumns.includes('expenseReportId') && (
                                                                        <td>
                                                                            {data?.expenseReportId}
                                                                        </td>
                                                                    )}
                                                                    {hiddenColumns.includes('expenseCategoryName') && (
                                                                        <td>
                                                                            {data?.expenseCategory?.title}
                                                                        </td>
                                                                    )}
                                                                    {hiddenColumns.includes('vendorName') && (
                                                                        <td>
                                                                            {data?.vendor?.name}
                                                                        </td>
                                                                    )}
                                                                    {hiddenColumns.includes('itemDate') && (
                                                                        <td className='text-center'>
                                                                            <DateComp formattedDate={data?.itemDate} />
                                                                        </td>
                                                                    )}
                                                                    {hiddenColumns.includes('currency') && (
                                                                        <td className='text-center'>
                                                                            {data?.currency?.title}
                                                                        </td>
                                                                    )}
                                                                    {hiddenColumns.includes('itemDebitOrCreditIndicator') && (
                                                                        <td className={`text-center ${data?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator ? 'text-danger' : null}`}>
                                                                            {data?.itemDebitOrCreditIndicator}
                                                                        </td>
                                                                    )}
                                                                    {hiddenColumns.includes('itemAmount') && (
                                                                        <td colSpan={2} className={`text-end pe-4 ${data?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator ? 'text-danger' : null}`}>
                                                                            <CurrencyComp amount={data?.itemAmount} />
                                                                        </td>
                                                                    )}
                                                                </>
                                                            }
                                                        </tr>
                                                    ))}
                                                </>) : (
                                                <tr>
                                                    <td colSpan={20}>
                                                        <NoRecordFound />
                                                    </td>
                                                </tr>
                                            )}
                                        </>}
                                    {isLoading && (
                                        <tr>
                                            <td colSpan={20} className="text-center">
                                                <LoadingComponent />
                                            </td>
                                        </tr>
                                    )}

                                </tbody>
                                {/* end::Table body */}
                            </table>
                        </div>
                        <Pagination
                            totalResult={data?.result?.totalRecords === 0 ? 0 : ((data?.result?.pageNumber - 1) * data?.result?.pageSize) + 1}
                            toResult={data?.result?.totalRecords === 0 ? 0 : Math.min(data?.result?.pageNumber * data?.result?.pageSize, data?.result?.totalRecords)}
                            ofResult={data?.result?.totalRecords}
                            onChange={handlePageSizeChange}
                            pageSize={pageSize}
                            onPageChange={handlePageClick}
                            pageCount={data?.result?.totalPages || 0}
                            currentPage={pageNumber}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserCCFeeds