
export interface AppConfig {
    MAIN_BASE_URL: string;
    REACT_APP_GOOGLE_MAPS_API_KEY: string;
    APP_VERSION: string
    MAIN_THIRD_PARTY_BASE_URL?: string;
    dev?: string;
    Uat?: string;
    microdev?: string;
    microUat?: string;
    adcs?: string;
    microAdcs?: string;
}

export const baseUrl: AppConfig = {
    MAIN_BASE_URL: "https://expenseapiadcs.azurewebsites.net",
    REACT_APP_GOOGLE_MAPS_API_KEY: "AIzaSyDRamKOTuCnMPw7b6BP2qdRWfuSJnIFpfI",
    APP_VERSION: "SS.W6.12.3.1",
    // APP_VERSION_ADCS: "SS.W6.11.29.1",
    // APP_VERSION_DEV: "SS.W6.11.21.1-DEV"
    // APP_VERSION_UAT: "SS.W6.11.21.1-UAT"

    // adcs_New :  https://spendsailapiprodadcs.azurewebsites.net

    // MAIN_THIRD_PARTY_BASE_URL: "https://spendsailmicroservicedev.azurewebsites.net",
    // BASE_URL: baseURL || "",
    // THIRD_PARTY_BASE_URL: microServiceURL || "",
    // dev: "https://spendsaildev.azurewebsites.net",
    // Uat: "https://spendsailuat.azurewebsites.net",
    // microdev: "https://spendsailmicroservicedev.azurewebsites.net",
    // microUat: "https://spendsailmicroserviceuat.azurewebsites.net",
    // adcs: "https://expenseapiadcs.azurewebsites.net",
    // microAdcs: "https://expenseapimicroserviceadcs.azurewebsites.net"
};

