import type {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { selectAuth } from "../features/authSlice";
import { RootState } from "../store";
import { API_END_POINTS } from './apiEndpoints';
import { baseUrl } from './baseUrl';

const rawBaseQuery = fetchBaseQuery({
    baseUrl: baseUrl.MAIN_BASE_URL,
})

type ExtraOptions = {
    headers?: Record<string, string>;
    // Define other optional properties here if needed
};

const dynamicBaseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    ExtraOptions
> = async (args, api, extraOptions) => {
    const orgBaseUrl = selectAuth(api.getState() as RootState).baseURL;
    // gracefully handle scenarios where data to generate the URL is missing
    if (!orgBaseUrl) {
        return {
            error: {
                status: 400,
                statusText: 'Bad Request',
                data: 'dynamic route not found',
            },
        }
    }

    const urlEnd = typeof args === 'string' ? args : args.url

    // construct a dynamically generated portion of the url
    const adjustedUrl = `${orgBaseUrl}/api/CreditCard${urlEnd}`
    const adjustedArgs = typeof args === 'string' ? { url: adjustedUrl } : { ...args, url: adjustedUrl };
    // Get the token from localStorage
    const token = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")!).token
        : null;
    // Set headers if token is available
    const headers = token ? { Authorization: `Bearer ${token}` } : {};
    // Merge headers with existing headers
    const mergedHeaders = { ...headers, ...extraOptions?.headers };
    // provide the amended url and other params to the raw base query
    return rawBaseQuery({ ...adjustedArgs, headers: mergedHeaders }, api, extraOptions)

}

export const CreditCardApi = createApi({
    reducerPath: 'CreditCardApi',
    baseQuery:dynamicBaseQuery,
    // baseQuery: fetchBaseQuery({
    //     baseUrl: baseUrl.BASE_URL + `/api/CreditCard/`,
    //     prepareHeaders: (headers) => {
    //         // Get the token from localStorage
    //         const token = localStorage.getItem("user")
    //             ? JSON.parse(localStorage.getItem("user")!).token
    //             : null;
    //         if (token) {
    //             headers.set('Authorization', `Bearer ${token}`);
    //         }

    //         return headers;
    //     },
    // }),
    endpoints: (builder) => ({
        addCreditCardInfo: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.addCreditCardInfo,
                    method: "POST",
                    body,
                }
            },
        }),
        updateCreditCardInfo: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.updateCreditCardInfo,
                    method: "PUT",
                    body,
                }
            },
        }),
        getAllCreditCardInfo: builder.query({
            query: (params) => {
                return {
                    url: `${API_END_POINTS.getAllCreditCardInfo}`,
                    method: "GET",
                    params
                }
            },
        }),
        getAllCreditCardTypeLookUp: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllCreditCardTypeLookUp,
                    method: "GET",
                }
            },
        }),
        changeCreditCardInfoStatus: builder.mutation({
            query: ({ CreditCardInfoId, status }) => {
                return {
                    url: `${API_END_POINTS.changeCreditCardInfoStatus}?CreditCardInfoId=${CreditCardInfoId}&status=${status}`,
                    method: "PUT",
                }
            },
        }),
        getAllCreditCardCatagoryLookUp: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllCreditCardCatagoryLookUp,
                    method: "GET"
                }
            },
        }),
        getAllCreditCardStatusLookUp: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllCreditCardStatusLookUp,
                    method: "GET"
                }
            },
        }),
    }),
})


export const {
    useAddCreditCardInfoMutation,
    useUpdateCreditCardInfoMutation,
    useGetAllCreditCardInfoQuery,
    useGetAllCreditCardTypeLookUpQuery,
    useChangeCreditCardInfoStatusMutation,
    useGetAllCreditCardCatagoryLookUpQuery,
    useGetAllCreditCardStatusLookUpQuery
} = CreditCardApi