import { Fragment, useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { KTSVG } from '../../../_metronic/helpers';
import { PageTitle } from '../../../_metronic/layout/core';
import { useGetAllWorkflowQuery } from '../../../services/WorkFlowApi';
import labelKey from "../../localization/label.json";
import WorkflowDetailModal from './WorkFlowDetailModal';
import { CreateworkflowModal } from './create-app-stepper/CreateWorkflowModal';
import { UpdateWorkflowModal } from './update-app-stepper/UpdateWorkflowModal';
import DateComp from '../../../components/dateComponent/DateComp';
import CurrencyComp from '../../../components/currencyComponent/CurrencyComp';
import WorkflowActiveConfirmationModal from './WorkflowActiveConfirmationModal';
import TableSettingMenu from '../../../components/tableHeading/TableSettingMenu';
import TextField from '../../../components/textFields/TextField';
import TableHeading from '../../../components/tableHeading/TableHeading';
import NoRecordFound from '../../../components/noRecord/NoRecordFound';
import { LoadingComponent } from '../../../components/loadingComponent/LoadingComponent';
import useColumnVisibility from '../../customHooks/useColumnVisibility';

const WorkFlowPage = () => {
    const { hiddenColumns, handleToggleColumn } = useColumnVisibility({ pageName: 'workflow' });
    const [showCreateWorkflowModal, setShowCreateWorkflowModal] = useState<boolean>(false)
    const [isChecked, setIsChecked] = useState(false)

    const { data: getAllWorkflow,
        isError: WorkflowIsError,
        isLoading: WorkflowIsLoading,
        refetch } = useGetAllWorkflowQuery('');

    const [showInnerTable, setShowInnerTable] = useState(null);
    const handleRowClick = (rowId: any) => {
        if (showInnerTable === rowId) {
            setShowInnerTable(null);
        } else {
            setShowInnerTable(rowId);
        }
    };

    const [showUpdateWorkflowModal, setShowUpdateWorkflowModal] = useState(false)
    const [editData, setEditData] = useState({});

    const editWorkflowHandler = () => {
        setShowUpdateWorkflowModal((prev) => !prev);
    };

    const [showWorkflowDetailModal, setShowWorkflowDetailModal] = useState(false)
    const [workflowDetailData, setWorkflowDetailData] = useState({});

    const workflowDetailHandler = () => {
        setShowWorkflowDetailModal((prev) => !prev);
    };
    const [showWorkflowActiveConfirmationModal, setShowWorkflowActiveConfirmationModal] = useState(false)
    const workflowActiveConfirmationHandler = () => {
        setShowWorkflowActiveConfirmationModal((prev) => !prev);
    };

    // const [prevModalsState, setPrevModalsState] = useState({
    //     createWorkflowModal: false,
    //     updateWorkflowModal: false,
    //     workflowDetailModal: false,
    //     showWorkflowActiveConfirmationModal: false,
    // });

    // // Update the previous modal state whenever the modals change
    // useEffect(() => {
    //     setPrevModalsState({
    //         createWorkflowModal: showCreateWorkflowModal,
    //         updateWorkflowModal: showUpdateWorkflowModal,
    //         workflowDetailModal: showWorkflowDetailModal,
    //         showWorkflowActiveConfirmationModal: showWorkflowActiveConfirmationModal
    //     });
    // }, [showCreateWorkflowModal, showUpdateWorkflowModal, showWorkflowDetailModal, showWorkflowActiveConfirmationModal]);

    // // Check for modal closing and trigger refetch
    // useEffect(() => {
    //     if (
    //         prevModalsState.createWorkflowModal === true && !showCreateWorkflowModal ||
    //         prevModalsState.updateWorkflowModal === true && !showUpdateWorkflowModal ||
    //         prevModalsState.workflowDetailModal === true && !showWorkflowDetailModal ||
    //         prevModalsState.showWorkflowActiveConfirmationModal === true && !showWorkflowActiveConfirmationModal
    //     ) {
    //         refetch();
    //     }
    // }, [
    //     showCreateWorkflowModal,
    //     showUpdateWorkflowModal,
    //     showWorkflowDetailModal,
    //     showWorkflowActiveConfirmationModal,
    //     prevModalsState.createWorkflowModal,
    //     prevModalsState.updateWorkflowModal,
    //     prevModalsState.workflowDetailModal,
    //     prevModalsState.showWorkflowActiveConfirmationModal,
    //     refetch,
    // ]);

    return (
        <>
            <CreateworkflowModal show={showCreateWorkflowModal} handleClose={() => setShowCreateWorkflowModal(false)} refetch={refetch} />
            <UpdateWorkflowModal show={showUpdateWorkflowModal} handleClose={() => setShowUpdateWorkflowModal(false)} data={editData} refetch={refetch} />
            <WorkflowDetailModal show={showWorkflowDetailModal} handleClose={() => setShowWorkflowDetailModal(false)} data={workflowDetailData} />
            <WorkflowActiveConfirmationModal show={showWorkflowActiveConfirmationModal} handleClose={() => setShowWorkflowActiveConfirmationModal(false)} data={editData} isChecked={isChecked} refetch={refetch} />

            <PageTitle>Workflow</PageTitle>
            <div className='card mb-5'>
                <div className='card-header border-0'>
                    <div className='card-title'>

                    </div>
                    <div className='card-toolbar'>
                        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                            <button type='button' className='btn btn-sm btn-light-primary' onClick={() => setShowCreateWorkflowModal(true)}>
                                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                                {labelKey.addWorkFlow}
                            </button>
                        </div>
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className="col-md-12">
                        <div className='table-responsive table-height'>
                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 '>
                                <thead>
                                    <tr className='fw-bold text-muted'>
                                        {hiddenColumns.includes('sr') && (
                                            <TableHeading
                                                label={labelKey.sr}
                                                columnId='sr'
                                                className="ps-4"
                                            />
                                        )}
                                        {hiddenColumns.includes('workflowName') && (
                                            <TableHeading
                                                label={labelKey.workflowName}
                                                columnId='workflowName'
                                            />
                                        )}
                                        {hiddenColumns.includes('workflowType') && (
                                            <TableHeading
                                                label={labelKey.workflowType}
                                                columnId='workflowType'
                                                className="w-400px"
                                            />
                                        )}
                                        {hiddenColumns.includes('status') && (
                                            <TableHeading
                                                label={labelKey.status}
                                                columnId='status'
                                            />
                                        )}
                                        {hiddenColumns.includes('users') && (
                                            <TableHeading
                                                label={labelKey.users}
                                                columnId='users'
                                                className='w-400px'
                                            />
                                        )}

                                        <th className='text-end rounded-end pe-2 border-0'>
                                            {hiddenColumns.includes('actions') && (
                                                <>
                                                    {labelKey.actions}
                                                </>
                                            )}
                                            <TableSettingMenu>
                                                <TextField
                                                    rightLabel={labelKey.sr}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("sr")}
                                                    checked={hiddenColumns.includes('sr')}
                                                    fieldClass='mb-4'
                                                />

                                                <TextField
                                                    rightLabel={labelKey.workflowName}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("workflowName")}
                                                    checked={hiddenColumns.includes('workflowName')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.workflowType}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("workflowType")}
                                                    checked={hiddenColumns.includes('workflowType')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.status}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("status")}
                                                    checked={hiddenColumns.includes('status')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.users}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("users")}
                                                    checked={hiddenColumns.includes('users')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.actions}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("actions")}
                                                    checked={hiddenColumns.includes('actions')}
                                                    fieldClass='mb-4'
                                                />
                                            </TableSettingMenu>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!WorkflowIsLoading &&
                                        <>
                                            {getAllWorkflow?.result?.length > 0 ? (
                                                <>
                                                    {getAllWorkflow?.result?.map((data: any, index: any) => (
                                                        <Fragment key={index}>

                                                            <Tooltip id="expand-row" place="bottom" />
                                                            <tr
                                                                data-tooltip-id="expand-row" data-tooltip-content='Click here to expand'
                                                                className={`cursor-pointer ${showInnerTable === data?.workflowId && data?.workflowId !== 1 && data?.workflowId !== 2 ? 'highlighted-row' : ''}`}
                                                                onClick={() => {
                                                                    handleRowClick(data?.workflowId);
                                                                }}>
                                                                {hiddenColumns.includes('sr') && (
                                                                    <td className='ps-4'>{index + 1} </td>
                                                                )}
                                                                {hiddenColumns.includes('workflowName') && (
                                                                    <td className='fw-semibold'>{data?.workflowName}</td>
                                                                )}
                                                                {hiddenColumns.includes('workflowType') && (
                                                                    <td className='fw-semibold'>{data?.workflowPolicyType?.value}</td>
                                                                )}
                                                                {hiddenColumns.includes('status') && (
                                                                    <td>
                                                                        <div className='d-flex align-items-center gap-2'>
                                                                            {data?.status === true ? <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.active}</span> :
                                                                                <span className="badge badge-light-danger fs-7 fw-semibold">{labelKey.inActive}</span>}
                                                                            <div className="form-check form-switch">
                                                                                <input
                                                                                    className="form-check-input cursor-pointer"
                                                                                    type="checkbox"
                                                                                    role="switch"
                                                                                    id={`flexSwitchCheckChecked-${data?.workflowId}`}
                                                                                    checked={data?.status}
                                                                                    onChange={(e) => {
                                                                                        setIsChecked(e.target.checked);
                                                                                        setEditData(data);
                                                                                        workflowActiveConfirmationHandler();
                                                                                    }}
                                                                                />
                                                                                <label className="form-check-label" htmlFor={`flexSwitchCheckChecked-${data?.workflowId}`}></label>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                )}
                                                                {hiddenColumns.includes('users') && (
                                                                    <td>
                                                                        <div className='d-flex gap-2 overflow-auto w-300px sm-scroll'>
                                                                            {data?.userAccountWithName?.map((UserNames: any, index1: any) => (
                                                                                <div key={index1}>
                                                                                    <span className="badge badge-light-primary fs-7 fw-semibold">{UserNames?.name}</span>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </td>
                                                                )}
                                                                {hiddenColumns.includes('actions') && (
                                                                    <td>
                                                                        <div className='d-flex align-items-center justify-content-end gap-2' >
                                                                            <Tooltip id="update-workflow" place="bottom" />
                                                                            <div
                                                                                data-tooltip-id="update-workflow" data-tooltip-content='Update Workflow'
                                                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                                                onClick={() => {
                                                                                    setEditData(data);
                                                                                    editWorkflowHandler();
                                                                                }}>
                                                                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                                                            </div>
                                                                            <Tooltip id="workflow-detail" place="bottom" />
                                                                            <div
                                                                                data-tooltip-id="workflow-detail" data-tooltip-content='Workflow Detail'
                                                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                                                onClick={() => {
                                                                                    setWorkflowDetailData(data?.workflowId);
                                                                                    workflowDetailHandler();
                                                                                }}>
                                                                                <KTSVG
                                                                                    path='/media/icons/duotune/arrows/arr064.svg'
                                                                                    className='svg-icon-3'
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                )}
                                                            </tr>
                                                            {showInnerTable === data?.workflowId && data?.workflowId !== 1 && data?.workflowId !== 2 && (
                                                                <tr className={`inner-row ${showInnerTable === data?.workflowId && data?.workflowId !== 1 && data?.workflowId !== 2 ? 'highlighted-row' : ''}`}>
                                                                    <td className='pt-0 px-3' colSpan={8}>
                                                                        <div className="table-responsive">
                                                                            <table className="table align-middle gs-0 gy-4">
                                                                                <thead>
                                                                                    <tr className='fw-bold text-muted bg-light'>
                                                                                        <th className='ps-4'>{labelKey.level}</th>
                                                                                        <th>{labelKey.approvers}</th>
                                                                                        <th>{labelKey.maximumAmount}</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {data?.workflowApprover?.length > 0 ? (
                                                                                        <>

                                                                                            {data?.workflowApprover?.map((approverData: any, index: any) => (
                                                                                                <tr key={index}>
                                                                                                    <td className='ps-5'>{approverData?.sequence}</td>
                                                                                                    <td>
                                                                                                        <div className='d-flex align-items-center'>
                                                                                                            <div className='symbol symbol-50px me-5'>
                                                                                                                <div className="symbol-label fs-3 bg-light-info text-info text-capitalize"
                                                                                                                >
                                                                                                                    {approverData?.userAccount?.firstName?.charAt(0)}
                                                                                                                </div>

                                                                                                            </div>
                                                                                                            <div className='d-flex justify-content-start flex-column'>
                                                                                                                <span className='text-dark fw-semibold  mb-1 fs-6 text-capitalize'>
                                                                                                                    {approverData?.userAccount?.firstName} {approverData?.userAccount?.lastName}
                                                                                                                </span>
                                                                                                                <span className='text-muted fw-semibold text-muted d-block fs-7 text-lowercase'>
                                                                                                                    {approverData?.userAccount?.email}
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td><CurrencyComp amount={approverData?.maximumAmount} /></td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <tr>
                                                                                            <td colSpan={3}>
                                                                                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                                                                    {labelKey.noMatchingRecordsFound}
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </Fragment>
                                                    ))}
                                                </>
                                            ) : (
                                                <tr>
                                                    <td colSpan={8}>
                                                        <NoRecordFound />
                                                    </td>
                                                </tr>
                                            )}
                                        </>
                                    }

                                    {WorkflowIsLoading && (
                                        <tr>
                                            <td colSpan={8} className="text-center">
                                                <LoadingComponent />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WorkFlowPage