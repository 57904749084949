import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import SelectComponent from 'react-select';
import { toast } from 'react-toastify';
import { KTSVG } from '../../../_metronic/helpers';
import TextField from '../../../components/textFields/TextField';
import { selectAuth } from '../../../features/authSlice';
import { useAppSelector } from '../../../hooks';
import { useManagerUsersQuery } from '../../../services/authApi';
import { useCreateBudgetMutation, useUpdateUserBudgetMutation } from '../../../services/BudgetApi';
import labelKey from "../../localization/label.json";
import tosterKey from "../../localization/toster.json";
// import Multiselect from 'multiselect-react-dropdown';
// import { MultiSelect } from "react-multi-select-component";
import Select from "react-dropdown-select";


type Props = {
    show: boolean
    handleClose: () => void
    budgetData?: any
}
type User = {
    value: string;
    label: string;
};
const AddBudgetModal = ({ show, handleClose, budgetData }: Props) => {
    const { userAccountID } = useAppSelector(selectAuth);
    const { data, refetch: usersLookUpRefetch } = useManagerUsersQuery({ managerId: userAccountID });
    const [addBudget, { isLoading, isSuccess, isError, error }] = useCreateBudgetMutation()
    const [updateBudget, { isLoading: updateIsLoading, isSuccess: updateIsSuccess, isError: updateIsError, error: updateError }] = useUpdateUserBudgetMutation()
    const initialValues = {
        budgetId: 0,
        userAccountIds: [
            0
        ],
        userAccount: {
            orgUserID: '',
            firstName: '',
            middleName: '',
            lastName: '',
            email: '',
            imageUrl: ''
        },
        allocateBudget: '',
        startDate: '',
        endDate: '',
        assignedBy: {
            orgUserID: userAccountID,
            firstName: '',
            middleName: '',
            lastName: '',
            email: '',
            imageUrl: ''
        }
    }
    const [updateId, setUpdateId] = useState(0);
    const [formValues, setFormValues] = useState(initialValues);

    useEffect(() => {
        // Restore form values when the modal reopens
        if (show && !budgetData && Object.keys(formValues).length !== 0) {
            formik.setValues(formValues);
        }
        // Update form values based on budgetData received
        if (budgetData) {
            setUpdateId(budgetData?.budgetId);
            formik.setValues({ ...budgetData });
        } else if (!show) {
            // Reset form values only when closing and it's not an update action
            setUpdateId(0);
            formik.resetForm();
        }
    }, [show, budgetData]);

    const formik = useFormik({
        initialValues,
        // validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (!values.allocateBudget) {
                toast.error("Budget is required");
                return;
            } else if (!values.startDate) {
                toast.error("Started date is required");
                return;
            } else if (!values.endDate) {
                toast.error("Ended date is required");
                return;
            } else if (!moment(values.startDate).isSameOrAfter(moment(), 'day')) {
                toast.error("The start date must be today or a future date. Please select a valid date.");
                return;
            } else if (!moment(values.endDate).isSameOrAfter(moment(values.startDate))) {
                toast.error("Ended date must be greater than or equal to the started date");
                return;
            } else if (updateId && !values.userAccount?.orgUserID) {
                toast.error("User is required");
                return;
            } else if (!updateId && !values.userAccountIds || values.userAccountIds?.length === 0) {
                toast.error("At least one user must be selected");
                return;
            }

            if (updateId) {
                updateBudget(values)
            } else {
                addBudget({
                    ...values,
                    assignedBy: {
                        orgUserID: userAccountID,
                    }
                })
            }
            // console.log(values);

            // addBudget(values)
            // resetForm()
        }
    })
    const { resetForm } = formik;

    useEffect(() => {
        if (isSuccess) {
            toast.success(tosterKey.budgetCreatedSuccessfully);
            handleClose();
            resetForm()
        }
    }, [isSuccess]);
    useEffect(() => {
        if (updateIsSuccess) {
            toast.success(tosterKey.budgetUpdatedSuccessfully);
            handleClose();
        }
    }, [updateIsSuccess]);
    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);
    useEffect(() => {
        if (updateIsError && updateError) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in updateError && updateError.data && typeof updateError.data === 'object' && 'message' in updateError.data) {
                errorMessage = (updateError.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [updateIsError, updateError]);

    const [selected, setSelected] = useState<User[]>([]);

    let options: User[] = [];

    if (data?.result && Array.isArray(data.result)) {
        options = data.result.map((user: any) => ({
            value: user.id,
            label: user.value.trim(),
        }));
    }
    // Store form values when the modal is closed
    const handleModalClose = () => {
        setFormValues(formik.values);
        setSelected([]);
        handleClose();
    };
    useEffect(() => {
        if (!show) {
            setSelected([]);
        }
        if (show) {
            usersLookUpRefetch()
        }
    }, [show]);

    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-md modal-dialog-centered'
                show={show}
                onHide={handleModalClose}
                backdrop="static"
            >
                <div className='modal-header'>
                    <h2 className='mb-0'>{updateId ? <>{labelKey.updateBudget}</> : <>{labelKey.addBudget}</>}</h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                    {/* end::Close */}
                </div>

                <form
                    className='form w-100'
                    onSubmit={formik.handleSubmit}
                >
                    <div className='modal-body py-lg-10 px-lg-10'>
                        <div className="row">
                            {updateId &&
                                <div className='col-md-6 mb-5'>
                                    <label className='form-label fw-bold text-dark fs-6 mb-2 required'>{labelKey.user}</label>
                                    <SelectComponent
                                        options={[
                                            { value: '', label: 'Select User' },
                                            ...(data?.result?.map((user: any) => ({
                                                value: user.id,
                                                label: user.value.trim(),
                                            })) || []),
                                        ]}
                                        className={`${formik.touched.userAccount?.orgUserID && formik.errors.userAccount?.orgUserID ? 'is-invalid' : ''
                                            }`}
                                        placeholder='Select User'
                                        value={
                                            formik.values.userAccount?.orgUserID
                                                ? {
                                                    value: formik.values.userAccount?.orgUserID,
                                                    label: formik.values.userAccount?.firstName || '',
                                                }
                                                : null
                                        }
                                        onChange={(selectedOption: any) => {
                                            formik.setFieldValue('userAccount.orgUserID', selectedOption.value);
                                            formik.setFieldValue('userAccount.firstName', selectedOption.label);
                                        }}
                                        // onChange={(selectedOption: any) => {
                                        //     formik.setFieldValue('userAccount.orgUserID', selectedOption ? String(selectedOption.value) : '');
                                        // }}
                                        onBlur={formik.handleBlur} // Add onBlur event to track the "touched" state of the field
                                    />

                                    {formik.touched.userAccount?.orgUserID && formik.errors.userAccount?.orgUserID && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.userAccount?.orgUserID}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            }

                            <div className={`${updateId ? 'col-md-6' : 'col-md-12'}  mb-5`}>
                                <TextField
                                    label={labelKey.budget}
                                    required={true}
                                    placeholder='Enter Budget'
                                    type='number'
                                    min={0}
                                    fieldClass={`${formik.touched.allocateBudget && formik.errors.allocateBudget ? 'is-invalid' : ''}`}
                                    {...formik.getFieldProps('allocateBudget')}
                                    value={formik.values.allocateBudget || ''}
                                />
                                {formik.touched.allocateBudget && formik.errors.allocateBudget && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.allocateBudget}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col-md-6 mb-5'>
                                <TextField
                                    label={labelKey.startDate}
                                    required={true}
                                    type='date'
                                    fieldClass={`${formik.touched.startDate && formik.errors.startDate ? 'is-invalid' : ''}`}
                                    {...formik.getFieldProps('startDate')}
                                    value={formik.values.startDate ? moment(formik.values.startDate).format('YYYY-MM-DD') : ''}
                                    max="9999-12-31"
                                />
                                {formik.touched.startDate && formik.errors.startDate && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.startDate}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col-md-6 mb-5'>
                                <TextField
                                    label={labelKey.endDate}
                                    required={true}
                                    type='date'
                                    fieldClass={`form-control form-control-lg form-control-solid ${formik.touched.endDate && formik.errors.endDate ? 'is-invalid' : ''
                                        }`}
                                    {...formik.getFieldProps('endDate')}
                                    value={formik.values.endDate ? moment(formik.values.endDate).format('YYYY-MM-DD') : ''}
                                    min={formik.values.startDate ? moment(formik.values.startDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}
                                    max="9999-12-31"
                                />
                                {formik.touched.endDate && formik.errors.endDate && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.endDate}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {!updateId &&
                                <div className='col-md-12 mb-5'>
                                    <label className='form-label fw-bold text-dark fs-6 mb-2 required'>{labelKey.user}</label>

                                    {/* <Multiselect
                                        options={[{ value: 'all', label: 'All Users Selected' }, ...options]}
                                        selectedValues={selected}
                                        onSelect={(selectedList) => {
                                            setSelected(selectedList);
                                            if (selectedList.some((option: any) => option.value === 'all')) {
                                                // If "Select All Users" is selected, select all users
                                                // setSelected([...options]);
                                                setSelected([{ value: 'all', label: 'All Users Selected' }, ...options]);
                                                formik.setFieldValue('userAccountIds', options.map(option => option.value));
                                            } else {
                                                formik.setFieldValue('userAccountIds', selectedList.map((option: any) => option.value));
                                            }
                                        }}
                                        onRemove={(selectedList) => {
                                            setSelected(selectedList.filter((option: any) => option.value !== 'all'));
                                            formik.setFieldValue('userAccountIds', selectedList.filter((option: any) => option.value !== 'all').map((option: any) => option.value));
                                        }}
                                        displayValue="label"
                                        showArrow={true}
                                        className='default-input rounded'
                                        showCheckbox={true}
                                    /> */}
                                    {/* <MultiSelect
                                        options={options}
                                        value={selected}
                                        // onChange={setSelected}
                                        onChange={(selectedList: any) => {
                                            setSelected(selectedList);
                                            formik.setFieldValue('userAccountIds', selectedList.map((option: any) => option.value));
                                        }}
                                        labelledBy="Select"
                                    /> */}
                                    <div className='react-multi-select'>
                                        <Select
                                            options={options}
                                            multi={true}
                                            selectAll={true}
                                            className="multi-select text-dark"
                                            onChange={(selectedList: any) => {
                                                setSelected(selectedList);
                                                formik.setFieldValue('userAccountIds', selectedList.map((option: any) => option.value));
                                            }} />
                                    </div>
                                </div>}
                        </div>
                    </div>
                    <div className='modal-footer justify-content-center'>
                        <Button variant='light' className='btn btn-light' onClick={handleClose}>{labelKey.cancel}</Button>
                        <button
                            type='submit'
                            className='btn btn-primary mb-5 mt-5'
                            disabled={isLoading}
                        >
                            {!isLoading && !updateIsLoading && <span className='indicator-label'>{updateId ? <>{labelKey.update}</> : <>{labelKey.submit}</>}</span>}
                            {updateId ?
                                <>
                                    {updateIsLoading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            {labelKey.pleaseWait}...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </> :
                                <>
                                    {isLoading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            {labelKey.pleaseWait}...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </>
                            }
                        </button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default AddBudgetModal
