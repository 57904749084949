import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import ReactReadMoreReadLess from "react-read-more-read-less";
import { Tooltip } from 'react-tooltip';
import { KTSVG } from '../../../../_metronic/helpers';
import { PageTitle } from '../../../../_metronic/layout/core';
import DateComp from '../../../../components/dateComponent/DateComp';
import DateTimeComp from '../../../../components/dateComponent/DateTimeComp';
import FilterMenu from '../../../../components/filterMenu/FilterMenu';
import { LoadingComponent } from '../../../../components/loadingComponent/LoadingComponent';
import NoRecordFound from '../../../../components/noRecord/NoRecordFound';
import Pagination from '../../../../components/pagination/Pagination';
import SearchBarComponent from '../../../../components/searchBar/SearchBarComponent';
import TableHeading from '../../../../components/tableHeading/TableHeading';
import TableSettingMenu from '../../../../components/tableHeading/TableSettingMenu';
import SearchSelect from '../../../../components/textFields/SearchSelect';
import TextField from '../../../../components/textFields/TextField';
import { constraintConfig } from '../../../../constraintConfig';
import { selectAuth } from '../../../../features/authSlice';
import { useAppSelector } from '../../../../hooks';
import { useGetAppRequestSeverityLookupQuery, useGetSupportRequestStatusLookupQuery, useGetSupportUserLookupQuery } from '../../../../services/GeneralApi';
import { useGetAllPortalSupportRequestQuery } from '../../../../services/PortalSupportApi';
import useColumnVisibility from '../../../customHooks/useColumnVisibility';
import labelKey from '../../../localization/label.json';
import { topics } from './HelpCenterDropdowns';
import UpdateSupportRequestModal from './UpdateSupportRequestModal';

interface Option {
  value: string | number;
  label: string;
}

const HelpSupportPage = () => {
  const { hiddenColumns, handleToggleColumn } = useColumnVisibility({ pageName: 'helpSupport' });
  const { roleID } = useAppSelector(selectAuth)
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10);
  const [searchStr, setSearchStr] = useState(''); // debounce search
  const [sortBy, setSortBy] = useState("createDT");
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortedColumn, setSortedColumn] = useState("createDT");
  const [immediateSearchStr, setImmediateSearchStr] = useState('');
  const [filterbyRequestDate, setFilterbyRequestDate] = useState('')
  const [filterByStatusId, setFilterByStatusId] = useState<Option | null>(null);
  const [filterbyTopic, setFilterbyTopic] = useState<Option | null>(null);
  const [filterbySection, setFilterbySection] = useState<Option | null>(null);
  const [filterByAssignToId, setFilterByAssignToId] = useState<Option | null>(null);
  const [filterBySeverityId, setFilterBySeverityId] = useState<Option | null>(null);

  const [filterEstimateDate, setFilterEstimateDate] = useState('')
  const [filterLastUpdateDate, setFilterLastUpdateDate] = useState('')
  const [isApplyingFilters, setIsApplyingFilters] = useState(false);

  const [localFilterbyRequestDate, setLocalFilterbyRequestDate] = useState('')
  const [localFilterByStatusId, setLocalFilterByStatusId] = useState<Option | null>(null);
  const [localFilterbyTopic, setLocalFilterbyTopic] = useState<Option | null>(null);
  const [localFilterbySection, setLocalFilterbySection] = useState<Option | null>(null);
  const [localFilterByAssignToId, setLocalFilterByAssignToId] = useState<Option | null>(null);
  const [localFilterBySeverityId, setLocalFilterBySeverityId] = useState<Option | null>(null);

  const [localFilterEstimateDate, setLocalFilterEstimateDate] = useState('')
  const [localFilterLastUpdateDate, setLocalFilterLastUpdateDate] = useState('')

  const { data: getSupportUserLookup, isLoading: getSupportUserLookupIsLoading } = useGetSupportUserLookupQuery('')
  const { data: getSupportStatusLookup, isLoading: getSupportStatusLookupIsLoading } = useGetSupportRequestStatusLookupQuery('')
  const { data: getSeverityLookup } = useGetAppRequestSeverityLookupQuery('')

  const { data, isLoading, refetch, isSuccess } = useGetAllPortalSupportRequestQuery({
    pageNumber: pageNumber,
    pageSize: pageSize,
    searchStr: searchStr,
    sortBy: sortBy,
    sortOrder: sortOrder,
    filterbyRequestDate: filterbyRequestDate,
    filterByStatusId: filterByStatusId ? filterByStatusId.value : 0,
    filterbyTopic: filterbyTopic ? filterbyTopic.value : '',
    filterbySection: filterbySection ? filterbySection.value : '',
    filterByAssignToId: filterByAssignToId ? filterByAssignToId.value : 0,
    estimateDate: filterEstimateDate,
    lastUpdateDate: filterLastUpdateDate,
    filterBySeverityId: filterBySeverityId ? filterBySeverityId.value : 0,
  })
  const [showUpdateSupportRequestModal, setShowUpdateSupportRequestModal] = useState(false)
  const [showUpdateSupportRequestEdit, setShowDivisionEdit] = useState({})
  const [viewOnlyData, setViewOnlyData] = useState(false)

  const divisionModalHandler = () => {
    setShowUpdateSupportRequestModal((prev) => !prev);
  };
 
  const handlePageSizeChange = (e: any) => {
    const newSize = parseInt(e.target.value);
    setPageSize(newSize);
    setPageNumber(1); // Reset page number to 1
  };
  // pagination
  const handlePageClick = (e: any) => {
    setPageNumber(e.selected + 1);
  };
  //per page record
  const handlePageRecords = (e: any) => {
    setPageNumber(1); // Reset the page to 1
  };
  const handleSort = (property: string) => {
    setSortBy(property);
    if (sortedColumn === property) {
      setSortOrder(prevSortOrder => (prevSortOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortedColumn(property);
      setSortOrder("asc");
    }
    // Perform additional sorting logic...
  };
  const handleSearchChange = (e: any) => {
    const { value } = e.target;
    setImmediateSearchStr(value); // Update immediate search state
    delayedSearch(value); // Update debounced search state
    setPageNumber(1)
  };

  const delayedSearch = useCallback(
    debounce((searchValue) => {
      setSearchStr(searchValue);
    }, 500),
    []
  );
  useEffect(() => {
    if (searchStr === "" ||
      sortOrder === "" ||
      sortBy === "") {
      refetch();
    }
  }, [searchStr, sortOrder, sortBy]);


  const handleFilterbyRequestDate = (selectedOption: any) => {

    setLocalFilterbyRequestDate(selectedOption.target.value);
  };
  const handleFilterByStatusId = (selectedOption: Option | null) => {
    setLocalFilterByStatusId(selectedOption);
  };
  const handleFilterbyTopic = (selectedOption: Option | null) => {
    setLocalFilterbyTopic(selectedOption);
  };
  const handleFilterbySection = (selectedOption: Option | null) => {
    setLocalFilterbySection(selectedOption);
  };
  const handleFilterByAssignToId = (selectedOption: Option | null) => {
    setLocalFilterByAssignToId(selectedOption);
  };
  const handleFilterBySeverityId = (selectedOption: Option | null) => {
    setLocalFilterBySeverityId(selectedOption);
  };
  const handleFilterEstimateDate = (selectedOption: any) => {
    setLocalFilterEstimateDate(selectedOption.target.value);
  };
  const handleFilterLastUpdateDate = (selectedOption: any) => {
    setLocalFilterLastUpdateDate(selectedOption.target.value);
  };

  const debouncedRefetch = debounce(() => {
    refetch().finally(() => {
      setIsApplyingFilters(false);
    });
  }, 300);
  useEffect(() => {
    if (isApplyingFilters) {
      debouncedRefetch();
    }
  }, [isApplyingFilters, isSuccess]);


  const applyFilters = () => {
    setIsApplyingFilters(true);
    setFilterbyRequestDate(localFilterbyRequestDate);
    setFilterByStatusId(localFilterByStatusId);
    setFilterbyTopic(localFilterbyTopic);
    setFilterbySection(localFilterbySection);
    setFilterByAssignToId(localFilterByAssignToId);
    setFilterBySeverityId(localFilterBySeverityId)
    setFilterEstimateDate(localFilterEstimateDate);
    setFilterLastUpdateDate(localFilterLastUpdateDate);
    setPageNumber(1)
  };
  const resetFilters = () => {
    setFilterbyRequestDate("")
    setFilterByStatusId(null);
    setFilterbyTopic(null);
    setFilterbySection(null);
    setFilterByAssignToId(null);
    setFilterBySeverityId(null)
    setFilterEstimateDate("")
    setFilterLastUpdateDate("")
    setLocalFilterbyRequestDate("")
    setLocalFilterByStatusId(null);
    setLocalFilterbyTopic(null);
    setLocalFilterbySection(null);
    setLocalFilterByAssignToId(null);
    setLocalFilterBySeverityId(null)
    setLocalFilterEstimateDate("")
    setLocalFilterLastUpdateDate("")
  }
  const modules = [
    ...(roleID !== constraintConfig.roleID.role10 ? [
      {
        id: 'Dashboard',
        value: 'Dashboard'
      },
      {
        id: 'Expense Wizard',
        value: 'Expense Wizard'
      },
      {
        id: 'Expense Line Items',
        value: 'Expense Line Items'
      },
      {
        id: 'Expense Reports',
        value: 'Expense Reports'
      },
      {
        id: 'Receipt Vault',
        value: 'Receipt Vault'
      },
      {
        id: 'Cards',
        value: 'Cards'
      },
      {
        id: 'Report Analytics',
        value: 'Report Analytics'
      },
    ] : []),
    ...(roleID !== constraintConfig.roleID.role5 && roleID !== constraintConfig.roleID.role10 ? [
      {
        id: 'Approval Queue',
        value: 'Approval Queue'
      },
    ] : []),
    ...(roleID === constraintConfig.roleID.role5 ? [
      {
        id: 'My Budget History',
        value: 'My Budget History'
      },
    ] : []),

    ...(roleID === constraintConfig.roleID.role4 ? [
      {
        id: 'Team Management',
        value: 'Team Management'
      },
      {
        id: 'Budget Allocation',
        value: 'Budget Allocation'
      },
    ] : []),
    ...(roleID === constraintConfig.roleID.role2 ? [
      {
        id: 'Expense Users',
        value: 'Expense Users'
      },
      {
        id: 'Org Settings',
        value: 'Org Settings'
      },
      {
        id: 'Vendor',
        value: 'Vendor'
      },
      {
        id: 'Workflow',
        value: 'Workflow'
      },
      {
        id: 'Mileage',
        value: 'Mileage'
      },
      {
        id: 'User Flex Budget',
        value: 'User Flex Budget'
      },
      {
        id: 'Category Budget Allocation',
        value: 'Category Budget Allocation'
      },
      {
        id: 'Categories',
        value: 'Categories'
      },
      {
        id: 'Locations',
        value: 'Locations'
      },
      {
        id: 'Departments',
        value: 'Departments'
      },
      {
        id: 'Divisions',
        value: 'Divisions'
      },
      {
        id: 'Countries',
        value: 'Countries'
      },
      {
        id: 'Expense Violation',
        value: 'Expense Violation'
      },
      {
        id: 'Automation - Bank CC Feed',
        value: 'Automation - Bank CC Feed'
      }
    ] : []),
    ...(roleID === constraintConfig.roleID.role10 ? [
      {
        id: 'Expense Approval',
        value: 'Expense Approval'
      },

    ] : [])
  ];
  return (
    <>
      <UpdateSupportRequestModal show={showUpdateSupportRequestModal}
        handleClose={() => setShowUpdateSupportRequestModal(false)} data={showUpdateSupportRequestEdit} viewOnlyData={viewOnlyData} refetch={refetch} />
      <PageTitle breadcrumbs={[]}>{labelKey.portalSupportRequests}</PageTitle>

      <div className='card mb-5'>
        <div className='card-header border-0 pt-6'>
          <SearchBarComponent
            placeholder='help request'
            value={immediateSearchStr}
            onChange={handleSearchChange}
          />
          <div className='card-toolbar'>
            <div className='d-flex justify-content-end'>
              <FilterMenu
                onclickApplyFilters={applyFilters}
                onclickResetFilters={resetFilters}
                isApplyingFiltersLoading={isApplyingFilters}
              >
                <>
                  <TextField
                    label={labelKey.requestDate}
                    type='date'
                    onChange={handleFilterbyRequestDate}
                    value={localFilterbyRequestDate}
                    fieldClass="mb-5"
                  />
                  <TextField
                    label={labelKey.estimatedDate}
                    type='date'
                    onChange={handleFilterEstimateDate}
                    value={localFilterEstimateDate}
                    fieldClass="mb-5"
                  />
                  <TextField
                    label={labelKey.lastUpdateDate}
                    type='date'
                    onChange={handleFilterLastUpdateDate}
                    value={localFilterLastUpdateDate}
                    fieldClass="mb-5"
                  />

                  <SearchSelect
                    label={labelKey.status}
                    options={[
                      { value: 0, label: 'Select Status' },
                      ...(getSupportStatusLookup?.result || []).map((option: any) => ({
                        value: option?.id,
                        label: option.value,
                        key: option.id,
                      }))]}
                    placeholder="Select Status"
                    value={localFilterByStatusId}
                    onChange={handleFilterByStatusId}
                    selectClass="mb-5"
                  />
                  <SearchSelect
                    label={labelKey.topic}
                    options={[
                      { value: '', label: 'Select Topic' },
                      ...(topics || []).map((option: any) => ({
                        value: option?.id,
                        label: option.value,
                        key: option.id,
                      }))]}
                    value={localFilterbyTopic}
                    placeholder="Select Topic"
                    onChange={handleFilterbyTopic}
                    selectClass="mb-5"
                  />
                  <SearchSelect
                    label={labelKey.section}
                    options={[
                      { value: '', label: 'Select Section' },
                      ...(modules || []).map((option: any) => ({
                        value: option?.id,
                        label: option.value,
                        key: option.id,
                      }))]}
                    placeholder="Select Section"
                    value={localFilterbySection}
                    onChange={handleFilterbySection}
                    selectClass="mb-5"
                  />
                  {roleID !== constraintConfig.roleID.roleIDPortalSupport &&
                    <SearchSelect
                      label={labelKey.assignedToUser}
                      options={[
                        { value: 0, label: 'Select User' },
                        ...(getSupportUserLookup?.result || []).map((option: any) => ({
                          value: option?.id,
                          label: option.value,
                          key: option.id,
                        }))]}
                      placeholder="Select User"
                      value={localFilterByAssignToId}
                      onChange={handleFilterByAssignToId}
                      selectClass="mb-5"
                    />}
                  <SearchSelect
                    label={labelKey.severity}
                    options={[
                      { value: 0, label: 'Select Severity' },
                      ...(getSeverityLookup?.result || []).map((option: any) => ({
                        value: option?.id,
                        label: option.value,
                        key: option.id,
                      }))]}
                    placeholder="Select Severity"
                    value={localFilterBySeverityId}
                    onChange={handleFilterBySeverityId}
                    selectClass="mb-5"
                  />
                </>
              </FilterMenu>
            </div>
          </div>

        </div>
        <div className='card-body py-8'>
          <div className="col-md-12">
            <div className='table-responsive table-height'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>

                    {hiddenColumns.includes('sr') && (
                      <TableHeading
                        label={labelKey.requestDate}
                        columnId='sr'
                        className="ps-4"
                      />
                    )}
                    {hiddenColumns.includes('requester') && (
                      <TableHeading
                        label={labelKey.requester}
                        columnId='requester'
                        className="ps-4"
                      />
                    )}
                    {hiddenColumns.includes('topic') && (
                      <TableHeading
                        label={labelKey.topic}
                        sortedColumn={sortedColumn}
                        sortOrder={sortOrder}
                        handleSort={handleSort}
                        columnId='topic'
                      />
                    )}
                    {hiddenColumns.includes('section') && (
                      <TableHeading
                        label={labelKey.section}
                        sortedColumn={sortedColumn}
                        sortOrder={sortOrder}
                        handleSort={handleSort}
                        columnId='section'
                      />
                    )}
                    {hiddenColumns.includes('subject') && (
                      <TableHeading
                        label={labelKey.subject}
                        sortedColumn={sortedColumn}
                        sortOrder={sortOrder}
                        handleSort={handleSort}
                        columnId='subject'
                        labelClassName='w-200px'
                      />
                    )}
                    {hiddenColumns.includes('description') && (
                      <TableHeading
                        label={labelKey.description}
                        sortedColumn={sortedColumn}
                        sortOrder={sortOrder}
                        handleSort={handleSort}
                        columnId='description'
                        labelClassName='w-300px'
                      />
                    )}
                    {hiddenColumns.includes('assignedTo') && (
                      <TableHeading
                        label={labelKey.assignedTo}
                        sortedColumn={sortedColumn}
                        sortOrder={sortOrder}
                        handleSort={handleSort}
                        columnId='assignedTo'
                      />
                    )}
                    {hiddenColumns.includes('requestTrackingID') && (
                      <TableHeading
                        label={labelKey.requestTrackingID}
                        sortedColumn={sortedColumn}
                        sortOrder={sortOrder}
                        handleSort={handleSort}
                        columnId='requestTrackingID'
                      />
                    )}
                    {hiddenColumns.includes('assignedToUserDate') && (
                      <TableHeading
                        label={labelKey.assignedToUserDate}
                        sortedColumn={sortedColumn}
                        sortOrder={sortOrder}
                        handleSort={handleSort}
                        columnId='assignedToUserDate'
                      />
                    )}
                    {hiddenColumns.includes('appResolutionType') && (
                      <TableHeading
                        label={labelKey.appResolutionType}
                        sortedColumn={sortedColumn}
                        sortOrder={sortOrder}
                        handleSort={handleSort}
                        columnId='appResolutionType'
                      />
                    )}
                    {hiddenColumns.includes('appRequestSeverity') && (
                      <TableHeading
                        label={labelKey.appRequestSeverity}
                        sortedColumn={sortedColumn}
                        sortOrder={sortOrder}
                        handleSort={handleSort}
                        columnId='appRequestSeverity'
                      />
                    )}
                    {hiddenColumns.includes('qaCompleted') && (
                      <TableHeading
                        label={labelKey.qaCompleted}
                        sortedColumn={sortedColumn}
                        sortOrder={sortOrder}
                        handleSort={handleSort}
                        columnId='qaCompleted'
                      />
                    )}
                    {hiddenColumns.includes('deploymentNeeded') && (
                      <TableHeading
                        label={labelKey.deploymentNeeded}
                        sortedColumn={sortedColumn}
                        sortOrder={sortOrder}
                        handleSort={handleSort}
                        columnId='deploymentNeeded'
                      />
                    )}
                    {hiddenColumns.includes('deploymentReady') && (
                      <TableHeading
                        label={labelKey.deploymentReady}
                        sortedColumn={sortedColumn}
                        sortOrder={sortOrder}
                        handleSort={handleSort}
                        columnId='deploymentReady'
                      />
                    )}
                    {hiddenColumns.includes('deployed') && (
                      <TableHeading
                        label={labelKey.deployed}
                        sortedColumn={sortedColumn}
                        sortOrder={sortOrder}
                        handleSort={handleSort}
                        columnId='deployed'
                      />
                    )}
                    {hiddenColumns.includes('estimatedDate') && (
                      <TableHeading
                        label={labelKey.estimatedDate}
                        sortedColumn={sortedColumn}
                        sortOrder={sortOrder}
                        handleSort={handleSort}
                        columnId='estimatedDate'
                      />
                    )}
                    {hiddenColumns.includes('lastUpdateDate') && (
                      <TableHeading
                        label={labelKey.lastUpdateDate}
                        sortedColumn={sortedColumn}
                        sortOrder={sortOrder}
                        handleSort={handleSort}
                        columnId='lastUpdateDate'
                      />
                    )}
                    {hiddenColumns.includes('status') && (
                      <TableHeading
                        label={labelKey.status}
                        sortedColumn={sortedColumn}
                        sortOrder={sortOrder}
                        handleSort={handleSort}
                        columnId='status'
                      />
                    )}
                    <th className='text-end rounded-end pe-2 border-0'>
                      {hiddenColumns.includes('action') && (
                        <>
                          {labelKey.actions}
                        </>
                      )}
                      <TableSettingMenu>
                        <TextField
                          rightLabel={labelKey.requestDate}
                          type="checkbox"
                          onChange={() => handleToggleColumn("sr")}
                          checked={hiddenColumns.includes('sr')}
                          fieldClass='mb-4'
                        />
                        <TextField
                          rightLabel={labelKey.requester}
                          type="checkbox"
                          onChange={() => handleToggleColumn("requester")}
                          checked={hiddenColumns.includes('requester')}
                          fieldClass='mb-4'
                        />
                        <TextField
                          rightLabel={labelKey.topic}
                          type="checkbox"
                          onChange={() => handleToggleColumn("topic")}
                          checked={hiddenColumns.includes('topic')}
                          fieldClass='mb-4'
                        />
                        <TextField
                          rightLabel={labelKey.section}
                          type="checkbox"
                          onChange={() => handleToggleColumn("section")}
                          checked={hiddenColumns.includes('section')}
                          fieldClass='mb-4'
                        />
                        <TextField
                          rightLabel={labelKey.subject}
                          type="checkbox"
                          onChange={() => handleToggleColumn("subject")}
                          checked={hiddenColumns.includes('subject')}
                          fieldClass='mb-4'
                        />
                        <TextField
                          rightLabel={labelKey.description}
                          type="checkbox"
                          onChange={() => handleToggleColumn("description")}
                          checked={hiddenColumns.includes('description')}
                          fieldClass='mb-4'
                        />
                        <TextField
                          rightLabel={labelKey.assignedTo}
                          type="checkbox"
                          onChange={() => handleToggleColumn("assignedTo")}
                          checked={hiddenColumns.includes('assignedTo')}
                          fieldClass='mb-4'
                        />
                        <TextField
                          rightLabel={labelKey.requestTrackingID}
                          type="checkbox"
                          onChange={() => handleToggleColumn("requestTrackingID")}
                          checked={hiddenColumns.includes('requestTrackingID')}
                          fieldClass='mb-4'
                        />
                        <TextField
                          rightLabel={labelKey.appResolutionType}
                          type="checkbox"
                          onChange={() => handleToggleColumn("appResolutionType")}
                          checked={hiddenColumns.includes('appResolutionType')}
                          fieldClass='mb-4'
                        />
                        <TextField
                          rightLabel={labelKey.appRequestSeverity}
                          type="checkbox"
                          onChange={() => handleToggleColumn("appRequestSeverity")}
                          checked={hiddenColumns.includes('appRequestSeverity')}
                          fieldClass='mb-4'
                        />
                        <TextField
                          rightLabel={labelKey.qaCompleted}
                          type="checkbox"
                          onChange={() => handleToggleColumn("qaCompleted")}
                          checked={hiddenColumns.includes('qaCompleted')}
                          fieldClass='mb-4'
                        />
                        <TextField
                          rightLabel={labelKey.deploymentNeeded}
                          type="checkbox"
                          onChange={() => handleToggleColumn("deploymentNeeded")}
                          checked={hiddenColumns.includes('deploymentNeeded')}
                          fieldClass='mb-4'
                        />
                        <TextField
                          rightLabel={labelKey.deployed}
                          type="checkbox"
                          onChange={() => handleToggleColumn("deployed")}
                          checked={hiddenColumns.includes('deployed')}
                          fieldClass='mb-4'
                        />
                        <TextField
                          rightLabel={labelKey.deploymentReady}
                          type="checkbox"
                          onChange={() => handleToggleColumn("deploymentReady")}
                          checked={hiddenColumns.includes('deploymentReady')}
                          fieldClass='mb-4'
                        />

                        <TextField
                          rightLabel={labelKey.estimatedDate}
                          type="checkbox"
                          onChange={() => handleToggleColumn("estimatedDate")}
                          checked={hiddenColumns.includes('estimatedDate')}
                          fieldClass='mb-4'
                        />
                        <TextField
                          rightLabel={labelKey.lastUpdateDate}
                          type="checkbox"
                          onChange={() => handleToggleColumn("lastUpdateDate")}
                          checked={hiddenColumns.includes('lastUpdateDate')}
                          fieldClass='mb-4'
                        />
                        <TextField
                          rightLabel={labelKey.status}
                          type="checkbox"
                          onChange={() => handleToggleColumn("status")}
                          checked={hiddenColumns.includes('status')}
                          fieldClass='mb-4'
                        />
                        <TextField
                          rightLabel={labelKey.action}
                          type="checkbox"
                          onChange={() => handleToggleColumn("action")}
                          checked={hiddenColumns.includes('action')}
                          fieldClass='mb-4'
                        />
                      </TableSettingMenu>
                    </th>

                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    <>
                      {data?.result?.data?.length > 0 ? (
                        <>
                          {data?.result?.data?.map((data: any, index: any) => (
                            <tr key={index}>
                              {hiddenColumns.includes("sr") && (
                                <td className='ps-4'>
                                  <DateTimeComp formattedDate={data?.createdAt} />
                                </td>
                              )}
                               {hiddenColumns.includes("requester") && (
                                <td>{data?.requester?.value}</td>
                              )}
                              {hiddenColumns.includes("topic") && (
                                <td>{data?.topic}</td>
                              )}
                              {hiddenColumns.includes("section") && (
                                <td>{data?.section}</td>
                              )}
                              {hiddenColumns.includes("subject") && (
                                <td className='w-200px text-wrap'>
                                  {data && data.subject && <ReactReadMoreReadLess
                                    charLimit={50}
                                    readMoreText={"Read more"}
                                    readLessText={"Read less"}
                                    readMoreClassName="readMore"
                                    readLessClassName="readLess"
                                  >
                                    {data.subject}
                                  </ReactReadMoreReadLess>}
                                </td>
                              )}
                              {hiddenColumns.includes("description") && (
                                <td className='w-300px text-wrap'>
                                  {data && data.description && <ReactReadMoreReadLess
                                    charLimit={50}
                                    readMoreText={"Read more"}
                                    readLessText={"Read less"}
                                    readMoreClassName="readMore"
                                    readLessClassName="readLess"
                                  >
                                    {data.description}
                                  </ReactReadMoreReadLess>}</td>
                              )}
                              {hiddenColumns.includes("assignedTo") && (
                                <td>{data?.assignedToOrgUser?.value}</td>
                              )}
                              {hiddenColumns.includes("requestTrackingID") && (
                                <td>{data?.requestTrackingID}</td>
                              )}
                              {hiddenColumns.includes("assignedToUserDate") && (
                                <td>{data?.assignedToUserDate}</td>
                              )}
                              {hiddenColumns.includes("appResolutionType") && (
                                <td>{data?.appResolutionType?.value}</td>
                              )}
                              {hiddenColumns.includes("appRequestSeverity") && (
                                <td>{data?.appRequestSeverity?.value}</td>
                              )}
                              {hiddenColumns.includes("qaCompleted") && (
                                <td>
                                  <TextField
                                    type='checkbox'
                                    checked={data?.qaCompleted}
                                    fieldClass='opacity-none ms-13'
                                    disabled={true} />
                                </td>
                              )}
                              {hiddenColumns.includes("deploymentNeeded") && (
                                <td>
                                  <TextField
                                    type='checkbox'
                                    checked={data?.deploymentNeeded}
                                    fieldClass='opacity-none ms-20'
                                    disabled={true} />
                                </td>
                              )}
                              {hiddenColumns.includes("deploymentReady") && (
                                <td>
                                  <TextField
                                    type='checkbox'
                                    checked={data?.deploymentReady}
                                    fieldClass='opacity-none ms-20'
                                    disabled={true}
                                  />
                                </td>
                              )}
                              {hiddenColumns.includes("deployed") && (
                                <td>
                                  <TextField
                                    type='checkbox'
                                    checked={data?.deployed}
                                    fieldClass='opacity-none ms-8'
                                    disabled={true} />
                                </td>
                              )}
                              {hiddenColumns.includes("estimatedDate") && (
                                <td><DateTimeComp formattedDate={data?.estimatedResolvedTime} /></td>
                              )}
                              {hiddenColumns.includes("lastUpdateDate") && (
                                <td><DateComp formattedDate={data?.lastUpdated} /></td>
                              )}
                              {hiddenColumns.includes("status") && (
                                <td>{data?.supportRequestStatus?.value}</td>
                              )}
                              <td>
                                {hiddenColumns.includes('action') && (
                                  <div className='d-flex align-items-center justify-content-end gap-2' >
                                    <Tooltip id="update-SupportRequest" place="top" />
                                    <div
                                      data-tooltip-id="update-SupportRequest" data-tooltip-content={labelKey.updateSupportRequest}
                                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                      onClick={() => {
                                        setViewOnlyData(false)
                                        setShowDivisionEdit(data);
                                        divisionModalHandler();
                                      }}
                                    >
                                      <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                    </div>
                                    <Tooltip id="detail" place="top" />
                                    <div
                                      data-tooltip-id="detail" data-tooltip-content={labelKey.detail}
                                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                      onClick={() => {
                                        setViewOnlyData(true)
                                        setShowDivisionEdit(data);
                                        divisionModalHandler();
                                      }}
                                    >
                                      <KTSVG
                                        path='/media/icons/duotune/arrows/arr064.svg'
                                        className='svg-icon-3'
                                      />
                                    </div>
                                  </div>
                                )}
                              </td>
                            </tr>
                          ))}
                        </>) : (
                        <tr>
                          <td colSpan={20}>
                            <NoRecordFound />
                          </td>
                        </tr>
                      )}
                    </>}
                  {isLoading && (
                    <tr>
                      <td colSpan={20} className="text-center">
                        <LoadingComponent />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <Pagination
              totalResult={data?.result?.totalRecords === 0 ? 0 : ((data?.result?.pageNumber - 1) * data?.result?.pageSize) + 1}
              toResult={data?.result?.totalRecords === 0 ? 0 : Math.min(data?.result?.pageNumber * data?.result?.pageSize, data?.result?.totalRecords)}
              ofResult={data?.result?.totalRecords}
              onChange={handlePageSizeChange}
              pageSize={pageSize}
              onPageChange={handlePageClick}
              pageCount={data?.result?.totalPages || 0}
              currentPage={pageNumber}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default HelpSupportPage