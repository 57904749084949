

export const API_END_POINTS = {
    login: `/Authenticate`,
    forgotPassword: `/ForgotPasswordRequest`,
    createUser: `/CreateUserAccount`,
    updateUser: `/UpdateUserAccount`,
    getAllUsers: `/GetAllUserAccounts`,
    getSingleUser: `/GetUserAccountDetailByID`,
    activeAndInActiveUser: `/ActivateDeactivateUserAccount`,
    changePassword: `/ChangePassword`,
    updatePassword: `/UpdatePassword`,
    passwordTokenValidate: `/PasswordTokenValidate`,
    getAllUserWithName: `/GetAllUserNameWithId`,
    userFilterByDepAndDesig: `/GetUserAccountsFilteredDepartmentAndDesignation`,
    getAllManagers: `/GetAllManagers`,
    getManagerTeam: `/GetManagerTeam`,
    updateProfileImage: `/AddAndUpdateProfile`,
    ImpersonateUser: `/ImpersonateUser`,
    managerUsers: `/GetManagerTeamLookup`,
    renewToken: `/RenewToken`,
    userPreferences: `/SetUserPreferences`,
    updateUserPreferences: `/UpdateUserPreferences`,
    getUserPreferences: `/GetUserPreferences`,
    userAccountDetailById: `/GetUserAccountCompleteDetailByID`,
    logout: `/Logout`,
    allManagersAndAdmins: `/GetAllManagersAndAdmins`,
    getOnlyUser: `/GetOnlyUser`,
    approvalUserDraft: `/approvalUserDraft`,
    getAllUsersLookupFilteredWorkFlowUsers: `/GetAllUsersLookupFilteredWorkFlowUsers`,
    deleteUserAccountImg: `/deleteUserAccountImg`,
    updateUserBasicInfo:`/UpdateUserBasicInfo`,
    // ----------organization EndPoints--------------
    getAllOrganization: `/GetAllOrganization`,
    getOrganizationDetailByID: `/GetOrganizationDetailByID`,
    createOrganization: `/CreateOrganization`,
    updateOrganization: `/UpdateOrganization`,
    getAllRoles: `/GetAllRoles`,
    createRole: `/CreateRole`,
    updateRole: `/UpdateRole`,
    createOrganizationWorkflow: `/CreateOrganizationWorkflow`,
    updateOrganizationOnboardingStatus: `/UpdateOrganizationOnboardingStatus`,
    // ----------budget EndPoints--------------
    createBudget: `/CreateUserBudget`,
    updateUserBudget: `/UpdateUserBudget`,
    managerTeamBudget: `/GetBudgetManagerTeam`,
    budgetHistory: `/GetBudgetHistoryOfUser`,
    latestBudget: `/GetLatestBudgetOfUser`,
    remaningBudget: `/GetRemainingBudgetOfUser`,
    budgetSubHistory: `/GetBudgetHistory`,
    createCatagoryBudgetAllocation: `/CreateCatagoryBudgetAllocation`,
    updateCatagoryBudgetAllocation: `/UpdateCatagoryBudgetAllocation`,
    getCatagoryBudgetAllocation: `/GetCatagoryBudgetAllocation`,
    activeOrInactiveCatagoryBudgetStatus: `/ActiveOrInactiveCatagoryBudgetStatus`,
    getRemainingCatagoryBudgetOfUser: `/GetRemainingCatagoryBudgetOfUser`,
    // ----------Dashboard EndPoints--------------
    totalActiveOrg: `/GetTotalAndActiveOrg`,
    totalActiveUsers: `/GetTotalAndActiveUser`,
    newInprogressOrg: `/GetNewAndInProgressOrg`,
    userGraphData: `/GetDashboardUserGraphData`,
    totalExpenseProfile: `/GetTotalExpenseAmountsForProfile`,
    expenseDataForUserGraph: `/GetUserExpenseDataForGraph`,
    expenseAmountForProfile: `/GetTotalExpenseAmountsForProfile`,
    managerDashboardData: `/GetManagerDashboardData`,
    adminDashboardData: `/GetAdminDashboardData`,
    userDashboardData: `/GetUserDashboardData`,
    getAPDashboardData: `/GetAPDashboardData`,
    getDashboardData: `/GetDashboardData`,
    getSupperAdminDashboardData: `/getSupperAdminDashboardData`,
    // ----------Expense EndPoints--------------
    createExpense: `/CreateExpense`,
    expenseByUser: `/GetExpensesByUser`,
    expenseItemById: `/GetExpenseItemsByExpenseId`,
    updateExpenseWithItem: `/UpdateExpenseWithItems`,
    deleteExpenseItem: `/DeleteExpenseItem`,
    updateExpenseItem: `/UpdateExpenseItem`,
    updateExpenseStatus: `/UpdateExpenseStatus`,
    expensesOfManagerTeam: `/GetExpensesOfManagerTeam`,
    expenseLog: `/GetExpenselog`,
    calculateDistance: `/CalculateDistance`,
    getMileage: `/GetMileage`,
    addUpdateMileage: `/AddUpdateMileage`,
    getCardExpenseItems: `/GetCardExpenseItems`,
    addUpdateExpenseItem: `/AddUpdateExpenseItem`,
    assignItemToNewExpense: `/AssignItemToNewExpense`,
    assignItemToExistingExpense: `/AssignItemToExistingExpense`,
    getExpenseToAddItems: `/GetExpenseToAddItems`,
    getExpenseForReport: `/GetExpenseForReport`,
    addClarificationNote: `/AddClarificationNote`,
    getMileagebyDate: `/GetMileagebyDate`,
    getExpensesForAP: `/GetExpensesForAP`,
    updateExpenseStatusToAccountsPayable: `/UpdateExpenseStatusToAccountsPayable`,
    updateExpenseToSpecificAP: `/UpdateExpenseToSpecificAP`,
    upsertExpenseViolation: `/UpsertExpenseViolation`,
    getExpenseApproversByExpenseId: `/GetExpenseApproversByExpenseId`,
    getExpenseItemsByExpenseItemId: `/GetExpenseItemsByExpenseItemId`,
    recallExpenseReport: `/RecallExpenseReport`,
    mergeLineItems: `/MergeLineItems`,
    reAssignExpenseToOtherManager: `/ReAssignExpenseToOtherManager`,
    getExpenselogDetail:`/GetExpenselogDetail`,
    updateExpenseStatusToPaid:`/UpdateExpenseStatusToPaid`,
    addExpenseItemPendinCC:`/AddExpenseItemPendinCC`,
    getExpenseApproversByExpenseIdV2:`/GetExpenseApproversByExpenseIdV2`,
    // ----------FamilyMember EndPoints--------------
    getFamilyMemberUsers: `/GetFamilyMemberByUser`,
    createFamilyMember: `/CreateFamilyMembver`,
    updateFamilyMember: `/UpdateFamilyMember`,
    deleteFamilyMember: `/DeleteFamilyMember`,
    getApprovedFamilyMemberByUser: `/GetApprovedFamilyMemberByUser`,
    // ----------ReportAnalytics EndPoints--------------
    getExpenseSummaryByUserId: `/GetExpenseSummaryByUserId`,
    getExpenseByCategory: `/GetExpenseByCategory`,
    getExpenseByPaymentMethod: `/GetExpenseByPaymentMethod`,
    getExpenseApproverSummery: `/GetExpenseApproverSummery`,
    getExpenseReportByUser: `/GetExpenseReportByUser`,
    getExpenseReportByDepartment: `/GetExpenseReportByDepartment`,
    getExpenseReportByStatus: `/GetExpenseReportByStatus`,
    getExpenseReportByPayment: `/getExpenseReportByPayment`,
    getExpenseReportByDepartmentWithAmount: `/GetExpenseReportByDepartmentWithAmount`,
    getExpenseReportByUserWithAmount: `/GetExpenseReportByUserWithAmount`,
    getExpensesReportByUser: `/GetExpensesReportByUser`,
    getReportIronePDF: `/GetReportIronePDF`,
    getReport: `/GetReport`,
    getExpenseReportByPaymentV2: `/GetExpenseReportByPaymentV2`,
    getExpensesReportByUserV2: `/GetExpensesReportByUserV2`,
    getExpenseByCategoryV2: `/GetExpenseByCategoryV2`,
    getExpenseReportByDepartmentAndUser: `/GetExpenseReportByDepartmentAndUser`,
    getExpenseViolationReport: `/GetExpenseViolationReport`,
    getExpenseCatagoryBudgetReport: `/GetExpenseCatagoryBudgetReport`,
    expenseItemsViewReportExport:`/ExpenseItemsViewReportExport`,
    // ----------Vendor & Card EndPoints--------------
    getAllVendor: `/GetAllVendor`,
    addVendor: `/AddVendor`,
    updateVendor: `/UpdateVendor`,
    getAllVendorLookUp: `/GetAllVendorLookUp`,
    changeVendorStatus: `/ChangeVendorStatus`,
    addCreditCardInfo: `/AddCreditCardInfo`,
    updateCreditCardInfo: `/UpdateCreditCardInfo`,
    getAllCreditCardInfo: `/GetAllCreditCardInfo`,
    getAllCreditCardTypeLookUp: `/GetAllCreditCardTypeLookUp`,
    changeCreditCardInfoStatus: `/ChangeCreditCardInfoStatus`,
    getAllCreditCardCatagoryLookUp: `/GetAllCreditCardCatagoryLookUp`,
    getAllCreditCardStatusLookUp: `/GetAllCreditCardStatusLookUp`,
    // ----------workflow EndPoints--------------
    getWorkflow: `/GetWorkflow`,
    getWorkflowByWorkflowId: `/GetWorkflowByWorkflowId`,
    createWorkflow: `/CreateWorkflow`,
    updateWorkflowWithApprovers: `/UpdateWorkflowWithApprovers`,
    getUserNotification: `/GetUserNotification`,
    getUserNotificationCount: `/GetUserNotificationCount`,
    getExpenseToApprove: `/GetExpenseToApprove`,
    getExpenseToApproveByExpenseId: `/GetExpenseToApproveByExpenseId`,
    updateStatusToApproveExpense: `/UpdateStatusToApproveExpense`,
    getWorkflowByUserAccountId: `/GetWorkflowByUserAccountId`,
    activeOrInactiveWorkflowStatus: `/ActiveOrInactiveWorkflowStatus`,
    getWorkflowApprovers: `/GetWorkflowApprovers`,
    // ------------Categories EndPoint ----------
    getAllExpenseCategory: `/GetAllExpenseCategory`,
    updateExpenseCategory: `/UpdateExpenseCategory`,
    createExpenseCategory: `/CreateExpenseCategory`,
    activeInActiveExpenseCategory:`/ActiveInActiveExpenseCategory`,
    // ----------general EndPoints--------------
    getAllFamilyRelationship: `/GetAllFamilyRelationship`,
    getAllFamilyMemberStatus: `/GetAllFamilyMemberStatus`,
    getAllExpenseCategoryLookup: `/GetAllExpenseCategory`,
    getExpenseSubCategory: `/GetExpenseSubCategory`,
    getAllCurrency: `/GetAllCurrency`,
    getAllExpenseStatus: `/GetAllExpenseStatus`,
    getAllDepartment: `/GetAllDepartment`,
    getAllDesignation: `/GetAllDesignation`,
    getAllExpenseItemType: `/GetAllExpenseItemType`,
    getAllWorkflowApproverStatus: `/GetAllWorkflowApproverStatus`,
    getManagerTeamLookup: `/GetManagerTeamLookup`,
    getAllExpensePaymentMethods: `/GetAllExpensePaymentMethods`,
    getAllProjectStatus: `/GetAllProjectStatus`,
    getAllClientsLookup: `/GetAllClientsLookup`,
    getAllCategory: `/GetAllCategory`,
    updateAllCategory: `/UpdateAllCategory`,
    getAllExpenseType: `/GetAllExpenseType`,
    getAllDesignationWithoutRole: `/GetAllDesignationWithoutRole`,
    configCategoryUserLevel: `/ConfigCategoryUserLevel`,
    getConfigCategoryByUser: `/GetConfigCategoryByUser`,
    updateUserCategory: `/UpdateUserCategory`,
    getAllNotificationPreferences: `/GetAllNotificationPreferences`,
    getAllDistancePreferences: `/GetAllDistancePreferences`,
    getDefaultApproversPolicyModeLookup: `/GetDefaultApproversPolicyModeLookup`,
    getAllOganizationLookup: `/GetAllOganizationLookup`,
    getOnboardingStatusLookup: `/GetOnboardingStatusLookup`,
    // createExpenseCategory: `/CreateExpenseCategory`,
    // updateExpenseCategory: `/UpdateExpenseCategory`,
    getAllLst_UserExpenseType: `/GetAllLst_UserExpenseType`,
    getAllLocations: `/GetAllLocations`,
    getAllLocationType: `/GetAllLocationType`,
    getAllTripType: `/GetAllTripType`,
    getDistanceByTripType: '/GetDistanceByTripType',
    getAllCity: `/GetAllCity`,
    getAllCountries: `/GetAllCountry`,
    getAllDivisionLookup: `/GetAllDivision`,
    getViolationPolicyTypeLookup: `/GetViolationPolicyTypeLookup`,
    getDistanceDataByTripType: `/GetDistanceDataByTripType`,
    getEmailSenderType: `/GetEmailSenderType`,
    getSupportUserLookup: `/GetSupportUserLookup`,
    getSupportRequestStatusLookup: `/GetSupportRequestStatusLookup`,
    getAppRequestSeverityLookup: `/GetAppRequestSeverityLookup`,
    getAppResolutionTypeLookup: `/GetAppResolutionTypeLookup`,
    getAllLookupsCacheList: `/GetAllLookupsCacheList`,
    clearAlllLookupsCache: `/ClearAlllLookupsCache`,
    getOcrReadStatusLookup: `/GetOcrReadStatusLookup`,
    getAllDepartmentByManager: `/GetAllDepartmentByManager`,
    getUsersByManagerId: `/GetUsersByManagerId`,
    getAllManagersLookup: `/GetAllManagers`,
    getReportExpenseStatus: `/GetReportExpenseStatus`,
    getAllUsersLocation: `/GetAllUsersLocation`,
    getAllUsersLookup: `/GetAllUsersLookup`,
    getAllTimeZone: `/GetAllTimeZone`,
    getAllUsersManagerAdmin: `/GetAllUsersManagerAdmin`,
    getAllCCFeedFile: `/GetAllCCFeedFile`,
    getPermissionsList:`/GetPermissionsList`,
    // ----------3rd Prty EndPoints--------------
    uploadFileToAzure: `/UploadFileToServer`,
    deleteFileToAzureServer: `/DeleteFileToServer`,
    getOCRvalueByfileURL: `/GetOCRvalueByfileURL`,
    uploadOCRInvoice: `/UploadOCRInvoice`,
    uploadOCRInvoices:`/UploadOCRInvoices`,
    uploadOCRInvoiceWithItemsId: `/UploadOCRInvoiceWithItemsId`,
    automationCreditCardFeedsFilesManualCall: `/AutomationCreditCardFeedsFilesManualCall`,
    automationCreaditCardFeedsToLineItemManualCall: `/AutomationCreaditCardFeedsToLineItemManualCall`,
    automationCreaditCardFeedsToCreateExpReportManualCall: `/AutomationCreaditCardFeedsToCreateExpReportManualCall`,
    uploadFileToServerForSupportAttachment: `/UploadFileToServerForSupportAttachment`,
    readDraftInvoices: `/ReadDraftInvoices`,
    // ----------DraftApi EndPoints--------------
    getAllUserDraft: `/GetAllUserDraft`,
    activateUserDraft: `/ActivateUserDraft`,
    getAllDraftInvoices: `/GetAllDraftInvoices`,
    deleteReceiptById: `/DeleteReceiptById`,
    // ----------OrgSettingApi EndPoints--------------
    getAllCountry: `/GetAllCountry`,
    addCountry: `/AddCountry`,
    updateCountry: `/UpdateCountry`,
    getCountryById: `/GetCountryById`,
    changeCountryStatus: `/ChangeCountryStatus`,
    getAllDepartmentLocation: `/GetAllDepartmentLocation`,
    addDepartmentLocation: `/AddDepartmentLocation`,
    updateDepartmentLocation: `/UpdateDepartmentLocation`,
    getDepartmentLocationById: `/GetDepartmentLocationById`,
    changeDepartmentLocationStatus: `/ChangeDepartmentLocationStatus`,
    getAllDivision: `/GetAllDivision`,
    addDivision: `/AddDivision`,
    updateDivision: `/UpdateDivision`,
    getDivisionById: `/GetDivisionById`,
    changeDivisionStatus: `/ChangeDivisionStatus`,
    getAllExpenseTypeCodes: `/GetAllExpenseTypeCodes`,
    addExpenseTypeCodes: `/AddExpenseTypeCodes`,
    updateExpenseTypeCodes: `/UpdateExpenseTypeCodes`,
    getExpenseTypeCodesById: `/GetExpenseTypeCodesById`,
    changeExpenseTypeCodesStatus: `/ChangeExpenseTypeCodesStatus`,
    getAllActiveExpenseViolations: `/GetAllActiveExpenseViolations`,
    addActiveExpenseViolations: `/AddActiveExpenseViolations`,
    updateActiveExpenseViolations: `/UpdateActiveExpenseViolations`,
    getActiveExpenseViolationsById: `/GetActiveExpenseViolationsById`,
    changeActiveExpenseViolationsStatus: `/ChangeActiveExpenseViolationsStatus`,
    getActiveExpenseViolationsByExpenseId: `/GetActiveExpenseViolationsByExpenseId`,
    getAllDepartmentList: `/GetAllDepartment`,
    addDepartment: `/AddDepartment`,
    updateDepartment: `/UpdateDepartment`,
    getDepartmentById: `/GetDepartmentById`,
    changeDepartmentStatus: `/ChangeDepartmentStatus`,
    getAllRequestLogs: `/GetAllRequestLogs`,
    // ----------OrgSettingApi EndPoints--------------
    getAllViolationPolicyField: `/GetAllViolationPolicyField`,
    addViolationPolicyField: `/AddViolationPolicyField`,
    updateViolationPolicyField: `/UpdateViolationPolicyField`,
    getViolationPolicyFieldById: `/GetViolationPolicyFieldById`,
    getExpenseViolationsByExpenseId: `/GetExpenseViolationsByExpenseId`,
    changeViolationPolicyFieldStatus: `/ChangeViolationPolicyFieldStatus`,
    getAllLocation: `/GetAllLocation`,
    addLocation: `/AddLocation`,
    updateLocation: `/UpdateLocation`,
    changeLocationStatus: `/ChangeLocationStatus`,
    getAutomationBankFilesFeedParsingStats: `/GetAutomationBankFilesFeedParsingStats`,
    // ---------automation api -----------
    getAllBankFilesLineItems: `/GetAllBankFilesLineitems`,
    getBankFilesLineitemsV2: `/GetBankFilesLineitemsV2`,
    getAllBankAndUserLineItems: `/GetAllBankAndUserLineItems`,
    getAllBankLineItems: `/GetAllBankLineItems`,
    getAllLineItemsDraftByUser:`/GetAllLineItemsDraftByUser`,
    //----------system settings-------------
    addUpdateEmailSetting: `/AddUpdateEmailSetting`,
    getEmailSettingBySenderType: `/GetEmailSettingBySenderType`,

    //--------- portal support--------------
    portalSupportRequest: `/PortalSupportRequest`,
    getAllPortalSupportRequest: `/GetAllPortalSupportRequest`,
    updatePortalSupportRequest: `/UpdatePortalSupportRequest`,
    deleteAttachmentPortalSupportRequest: `/DeleteAttachmentPortalSupportRequest`,

    //-----------BC integration---------------
    syncExpensesSSToBC: `/SyncExpensesSSToBC`,
    syncAllExpensesSSToBC:`/SyncAllExpensesSSToBC`
};