import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
// import Select from 'react-select';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import CurrencyComp from '../../../components/currencyComponent/CurrencyComp';
import { LoadingComponent } from '../../../components/loadingComponent/LoadingComponent';
import NoRecordFound from '../../../components/noRecord/NoRecordFound';
import Pagination from '../../../components/pagination/Pagination';
import SearchBarComponent from '../../../components/searchBar/SearchBarComponent';
import TableHeading from '../../../components/tableHeading/TableHeading';
import TableSettingMenu from '../../../components/tableHeading/TableSettingMenu';
import TextField from '../../../components/textFields/TextField';
import { constraintConfig } from '../../../constraintConfig';
import { useExpenseItemsViewReportExportQuery } from '../../../services/ReportAndAnalyticsApi';
import useColumnVisibility from '../../customHooks/useColumnVisibility';
import labelKey from "../../localization/label.json";

import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { Spinner } from 'react-bootstrap';
import { FaEye } from 'react-icons/fa6';
import Lightbox from 'react-image-lightbox';
import Select, { components } from 'react-select';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import { KTSVG } from '../../../_metronic/helpers';
import DateComp from '../../../components/dateComponent/DateComp';
import DateRangeComp from '../../../components/dateComponent/DateRangeComp';
import RefreshComponent from '../../../components/refreshComponent/RefreshComponent';
import SearchSelect from '../../../components/textFields/SearchSelect';
import { useExpenseStatusLookupQuery, useGetAllUsersManagerAdminQuery } from '../../../services/GeneralApi';
import CategoriesWithIcons from '../expenseItems/CategoriesWithIcons';
import { useLazyGetExpenseItemByExpenseIdQuery, useLazyGetExpenseItemsByExpenseItemIdQuery } from '../../../services/ExpenseApi';
import ExpenseDetailModal from '../expenseItems/ExpenseDetailModal';
import { UpdateExpenseItemModal } from '../expenseItems/update-single-Item/UpdateExpenseItemModal';
import SingleItemDetailModal from '../expenseItems/update-single-Item/SingleItemDetailModal';


interface LightboxData {
  imageURL: string;
}
const { Option } = components;
type Option = {
  value: string;
  label: string;
};

// Custom Option component to add checkboxes
const CheckboxOption = (props: any) => {
  return (
    <Option {...props} className='d-flex align-items-start gap-2'>
      <input
        type="checkbox"
        checked={props.isSelected}
        onChange={() => null}
      />{" "}
      <label>{props.label}</label>
    </Option>
  );
};

const ExportExpenseReports = () => {
  const { hiddenColumns, handleToggleColumn } = useColumnVisibility({ pageName: 'exportReports' });
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxData, setLightboxData] = useState<LightboxData>({ imageURL: '' });
  const currentDate = moment().format('MM/DD/YYYY');
  const sevenDaysAgo = moment().subtract(6, 'days').format('MM/DD/YYYY');
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10);
  const [searchStr, setSearchStr] = useState(''); // debounce search
  const [immediateSearchStr, setImmediateSearchStr] = useState('');
  const [sortBy, setSortBy] = useState("createdDate");
  const [sortOrder, setSortOrder] = useState("desc");
  // const [filterByMonth, setfilterByMonth] = useState("");
  const [filterByStartDate, setFilterByStartDate] = useState(sevenDaysAgo);
  const [filterByEndDate, setFilterByEndDate] = useState(currentDate);
  const [sortedColumn, setSortedColumn] = useState("createdDate");
  const [localFilterByStartDate, setLocalFilterByStartDate] = useState(sevenDaysAgo);
  const [localFilterByEndDate, setLocalFilterByEndDate] = useState(currentDate);
  const [filterByUserIds, setFilterByUserIds] = useState<string[]>([]);
  const [localFilterByUserIds, setLocalFilterByUserIds] = useState<string[]>([]);

  const [selectedUserIds, setSelectedUserIds] = useState<Option[]>([]);
  const [filterByStatus, setFilterByStatus] = useState(0);
  const [localFilterByStatus, setLocalFilterByStatus] = useState(0);

  const [isApplyingFilters, setIsApplyingFilters] = useState(false);

  const { data: getUserByManager } = useGetAllUsersManagerAdminQuery('')
  const { data: getAllExpenseStatus } = useExpenseStatusLookupQuery('')


  const { data, isFetching: isLoading, refetch, isSuccess } = useExpenseItemsViewReportExportQuery({
    pageNumber: page,
    pageSize: pageSize,
    sortBy: sortBy,
    sortOrder: sortOrder,
    searchStr: searchStr,
    startDate: filterByStartDate,
    endDate: filterByEndDate,
    userIds: filterByUserIds.length > 0 ? filterByUserIds : null,
    reportStatusId: filterByStatus
  })
  const [expenseItemByIdTrigger, { data: expenseItemData, isFetching }] = useLazyGetExpenseItemsByExpenseItemIdQuery({})

  const handlePageSizeChange = (e: any) => {
    const newSize = parseInt(e.target.value);
    setPageSize(newSize);
    setPage(1); // Reset page number to 1
  };
  // pagination
  const handlePageClick = (e: any) => {
    setPage(e.selected + 1);
  };

  const handleSort = (property: string) => {
    setSortBy(property);
    if (sortedColumn === property) {
      setSortOrder(prevSortOrder => (prevSortOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortedColumn(property);
      setSortOrder("asc");
    }
  };

  //per page record
  const handlePageRecords = (e: any) => {
    setPage(1); // Reset the page to 1
  };
  const handleSearchChange = (e: any) => {
    const { value } = e.target;
    setImmediateSearchStr(value); // Update immediate search state

    delayedSearch(value); // Update debounced search state
    setPage(1);
  };

  const delayedSearch = useCallback(
    debounce((searchValue) => {
      setSearchStr(searchValue);
    }, 500),
    []
  );

  const handleDateRangeChange = (event: any, picker: any) => {
    const startDateFormatted = moment(picker.startDate).format('MM-DD-YYYY');
    const endDateFormatted = moment(picker.endDate).format('MM-DD-YYYY');

    setLocalFilterByStartDate(startDateFormatted);
    setLocalFilterByEndDate(endDateFormatted);
  };
  const handleCancelDateSelection = (event: any, picker: any) => {
    // Reset the state to empty strings
    setFilterByStartDate("");
    setFilterByEndDate("");
    setLocalFilterByStartDate("");
    setLocalFilterByEndDate("");
  };

  const userIdsOptions: Option[] = getUserByManager?.result?.map((userByManager: any) => ({
    value: userByManager.id,
    label: userByManager.value,
  }));
  const handleUserIdsChange = (selected: Option[]) => {
    setSelectedUserIds(selected);
    setLocalFilterByUserIds(selected?.map((userByManager) => userByManager.value));
  };

  const handleFilterByStatus = (selectedOption: any) => {
    setLocalFilterByStatus(selectedOption.value);
  };

  const applyFilters = () => {
    setIsApplyingFilters(true);
    setFilterByStartDate(localFilterByStartDate);
    setFilterByEndDate(localFilterByEndDate);
    setFilterByUserIds(localFilterByUserIds)
    setFilterByStatus(localFilterByStatus)
    setPage(1);
  };
  const resetFilters = () => {
    setFilterByStartDate(sevenDaysAgo);
    setFilterByEndDate(currentDate);
    setFilterByUserIds([])
    setFilterByStatus(0)

    setLocalFilterByStartDate(sevenDaysAgo);
    setLocalFilterByEndDate(currentDate);
    setLocalFilterByUserIds([])
    setLocalFilterByStatus(0)
    setSelectedUserIds([])
  }


  const debouncedRefetch = debounce(() => {
    refetch().finally(() => {
      setIsApplyingFilters(false);
    });
  }, 300);
  useEffect(() => {
    if (isApplyingFilters) {
      debouncedRefetch();
    }
  }, [isApplyingFilters]);

  useEffect(() => {
    if (
      searchStr === "" ||
      sortOrder === "" ||
      sortBy === "") {
      refetch();
    }
  }, [searchStr, sortOrder, sortBy]);

  const exportExpenseSummaryReport = async () => {
    if (data?.result?.data) {
      // Create a new workbook and worksheet
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Expense Log');

      // Define columns dynamically based on hiddenColumns
      worksheet.columns = [
        { header: 'Item Date', key: 'itemTransactionDate', width: 20 },
        { header: 'Report Date', key: 'reportDate', width: 20 },
        { header: 'BC Sync Date', key: 'syncSSToBCDateTime', width: 25 },
        { header: 'Report ID', key: 'sS_ReportNo', width: 15 },
        { header: 'Report Title', key: 'reportTitle', width: 15 },
        { header: 'User', key: 'submitted_By', width: 20 },
        { header: 'Amount', key: 'amount', width: 15 },
        { header: 'Payment Method', key: 'paymentMethod', width: 15 },
        { header: 'Report Status', key: 'reportStatus', width: 15 },
        { header: 'Assigned To', key: 'assignedToName', width: 30 },
        { header: 'Receipt URL', key: 'receipt', width: 20 },
      ].filter(Boolean); // Remove null values for hidden columns

      worksheet.getRow(1).font = { bold: true };

      // Add rows from tableData
      data?.result?.data.forEach((data: any) => {
        worksheet.addRow({
          itemTransactionDate: data?.itemTransactionDate,
          reportDate: data?.reportDate,
          syncSSToBCDateTime: data?.syncSSToBCDateTime,
          sS_ReportNo: data?.sS_ReportNo,
          reportTitle: data?.reportTitle,
          submitted_By: data?.submitted_By,
          amount: data?.amount,
          paymentMethod: data?.paymentMethod,
          reportStatus: formatStatus(data?.expenseStatusId), // Assuming status formatting is handled
          assignedToName: data?.assignedToName,
          receipt: data?.receipt,
        });
      });

      // Generate Excel file and save
      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buffer]), `ExportReport_${Date.now()}.xlsx`);
    }
  };
  const exportExpenseTransactionDetail = async () => {
    if (data?.result?.data) {
      // Create a new workbook and worksheet
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Expense Log');

      // Define columns dynamically based on hiddenColumns
      worksheet.columns = [
        { header: 'Item Date', key: 'itemTransactionDate', width: 20 },
        { header: 'Report Date', key: 'reportDate', width: 20 },
        { header: 'BC Sync Date', key: 'syncSSToBCDateTime', width: 25 },
        { header: 'Report ID', key: 'sS_ReportNo', width: 15 },
        { header: 'Report Title', key: 'reportTitle', width: 15 },
        { header: 'User', key: 'submitted_By', width: 20 },
        { header: 'Amount', key: 'amount', width: 15 },
        { header: 'Category', key: 'categoryName', width: 20 },
        { header: 'Payment Method', key: 'paymentMethod', width: 15 },
        { header: 'Report Status', key: 'reportStatus', width: 15 },
        { header: 'Department Code', key: 'chargeDepartmentCode', width: 20 },
        { header: 'Department Name', key: 'chargeDepartmentName', width: 25 },
        { header: 'Split ToLocations', key: 'splitToLocationsName', width: 50 },
        { header: 'Charge Location Code', key: 'chargeLocationCode', width: 20 },
        { header: 'Charge Location Name', key: 'chargeLocationName', width: 25 },
        { header: 'Assigned To', key: 'assignedToName', width: 30 },
        { header: 'Receipt URL', key: 'receipt', width: 20 },
        { header: 'Report Submitted Date', key: 'reportSubmittedDate', width: 25 },
        { header: 'AP Approved Date', key: 'apApprovedDate', width: 20 },
        { header: 'Paid Date', key: 'paidDate', width: 20 },
        { header: 'Source', key: 'transactionSource', width: 20 },

      ].filter(Boolean); // Remove null values for hidden columns

      worksheet.getRow(1).font = { bold: true };

      // Add rows from tableData
      data?.result?.data.forEach((data: any) => {
        worksheet.addRow({
          itemTransactionDate: data?.itemTransactionDate,
          reportDate: data?.reportDate,
          syncSSToBCDateTime: data?.syncSSToBCDateTime,
          sS_ReportNo: data?.sS_ReportNo,
          reportTitle: data?.reportTitle,
          submitted_By: data?.submitted_By,
          amount: data?.amount,
          categoryName: data?.categoryName,
          paymentMethod: data?.paymentMethod,
          reportStatus: formatStatus(data?.expenseStatusId), // Assuming status formatting is handled
          chargeDepartmentCode: data?.chargeDepartmentCode,
          chargeDepartmentName: data?.chargeDepartmentName,
          splitToLocationsName: data?.splitToLocationsName,
          chargeLocationCode: data?.chargeLocationCode,
          chargeLocationName: data?.chargeLocationName,
          assignedToName: data?.assignedToName,
          receipt: data?.receipt,
          reportSubmittedDate: data?.reportSubmittedDate,
          apApprovedDate: data?.apApprovedDate,
          paidDate: data?.paidDate,
          transactionSource: data?.transactionSource,
        });
      });

      // Generate Excel file and save
      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buffer]), `EXPENSE TRANSACTION DETAIL.xlsx`);
    }
  };

  // Helper function to format the report status
  const formatStatus = (statusId: number) => {
    switch (statusId) {
      case constraintConfig.expenseStatus.drafted:
        return labelKey.draft;
      case constraintConfig.expenseStatus.pendingForApproval:
        return labelKey.pendingForApproval;
      case constraintConfig.expenseStatus.approved:
        return labelKey.approved;
      case constraintConfig.expenseStatus.rejected:
        return labelKey.rejected;
      case constraintConfig.expenseStatus.apApproved:
        return labelKey.aPApproved;
      case constraintConfig.expenseStatus.processing:
        return labelKey.processing;
      case constraintConfig.expenseStatus.paid:
        return labelKey.paid;
      default:
        return '';
    }
  };
  const handleRefresh = () => {
    // Set the state values
    // setFilterByStartDate(sevenDaysAgo);
    // setFilterByEndDate(currentDate);
    // setLocalFilterByStartDate(sevenDaysAgo);
    // setLocalFilterByEndDate(currentDate);

    // Trigger refetch after state updates are done
    setTimeout(() => {
      refetch()
        .then(() => {
          if (isSuccess) {
            toast.success('Data Refresh successfully!');
          }
        });
    }, 0);
  };

  const isPdfFile = (filePath: string | undefined) => {
    if (!filePath) return false;
    const fileExtension = filePath.toLowerCase().split('.').pop();
    return fileExtension === 'pdf';
  };
  const openPdfInBrowserOrLightbox = (attachmentFile: string | undefined) => {
    if (attachmentFile) {
      if (isPdfFile(attachmentFile)) {
        // For PDF files, open in the browser
        window.open(attachmentFile, '_blank');
      } else {
        // For image files, open in the Lightbox
        setLightboxData({ imageURL: attachmentFile });
        setLightboxOpen(true);
      }
    }
  };


  const [showUpdateExpenseItemModal, setShowUpdateExpenseItemModal] = useState(false)
  const expenseItemModalHandler = () => {
    setShowUpdateExpenseItemModal((prev) => !prev);
  };
  return (
    <>
      <SingleItemDetailModal
        show={showUpdateExpenseItemModal}
        handleClose={() => setShowUpdateExpenseItemModal(false)}
        data={expenseItemData?.result}
        viewOnlyData={true} />
      <div className='card' style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
        <div className='card-header border-0  align-items-center'>
          <div className='d-flex align-items-center gap-2'>
            <SearchBarComponent
              placeholder='Expense'
              value={immediateSearchStr}
              onChange={handleSearchChange}
            />
            <RefreshComponent onClick={handleRefresh} />
          </div>

          <div className='d-flex gap-2 align-items-center ms-auto'>
            <button
              type='button'
              className='btn btn-sm btn-light-primary me-3 d-flex justify-content-end'
              role='button'
              data-bs-toggle='collapse'
              data-bs-target='#report_filters'
              aria-expanded='true'
              aria-controls='report_filters'
            >
              <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
              {labelKey.filter}
            </button>
            <button type='button' className='btn btn-sm btn-info ' onClick={exportExpenseSummaryReport}>
              <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
              Export Expense Summary Report
            </button>
            <button type='button' className='btn btn-sm btn-info ' onClick={exportExpenseTransactionDetail}>
              <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
              Export Expense Transaction Detail
            </button>
          </div>
        </div>
        <div className='card-body pb-5 pt-0'>

          <div className='row  mt-3 border-0  align-items-end collapse border-bottom' id='report_filters'>
            <div className='col-md-4 col-lg-3 col-xl-2 mb-5'>
              <DateRangeComp
                startDate={localFilterByStartDate || moment().subtract(6, 'days')}
                endDate={localFilterByEndDate || moment()}
                onApply={handleDateRangeChange} onCancel={handleCancelDateSelection}
                value={localFilterByStartDate && localFilterByEndDate ? `${localFilterByStartDate} - ${localFilterByEndDate}` : "MM/DD/YYYY"} />
            </div>
            <div className='col-md-4 col-lg-3 col-xl-2 mb-5'>
              <label className='form-label fs-6 fw-bold'>{labelKey.users}:</label>
              <Select
                isMulti
                options={userIdsOptions}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option: CheckboxOption }}
                onChange={(selected) => handleUserIdsChange(selected as Option[])} // Casting to Option[]
                value={selectedUserIds} // Directly use selectedDeptId as it is of type Option[]
                placeholder='Search User...'
                className='filter-valueContainer'
              />
            </div>
            <div className='col-md-4 col-lg-3 col-xl-2 mb-5'>
              <SearchSelect
                label={`${labelKey.status}:`}
                options={[
                  // { value: 0, label: 'All' }, // Add the empty option
                  ...(Array.isArray(getAllExpenseStatus?.result)
                    ? getAllExpenseStatus.result
                      .filter((option: any) => option.id !== 6) // Exclude the option with id === 6
                      .map((option: any) => ({
                        value: option.id,
                        label: option?.value,
                      }))
                    : [])
                ]}
                placeholder="Select Status..."
                value={
                  localFilterByStatus && localFilterByStatus !== 0
                    ? {
                      value: localFilterByStatus,
                      label: getAllExpenseStatus?.result
                        .find((option: any) => option.id === localFilterByStatus)?.value
                    }
                    : null
                }
                onChange={handleFilterByStatus}
              />

            </div>
            <div className='col-md-4 col-lg-3 col-xl-2 mb-5'>
              <div className='d-flex align-items-end h-100 gap-3'>
                <button className='btn btn-danger pull-right me-3' onClick={resetFilters}>Reset Filters</button>

                <button className='btn btn-primary pull-right' onClick={applyFilters}>
                  {isApplyingFilters ? <>
                    <span>loading...</span>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  </> : "Apply Filters"}
                </button>

              </div>
            </div>

          </div>
          <div className="row">
            <div className="col-md-12">
              <div className='table-responsive table-height'>
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='fw-bold text-muted'>
                      {hiddenColumns.includes('itemTransactionDate') && (
                        <TableHeading
                          label={labelKey.itemDate}
                          sortedColumn={sortedColumn}
                          sortOrder={sortOrder}
                          handleSort={handleSort}
                          columnId='itemTransactionDate'
                          className="ps-4"
                        />
                      )}
                      {hiddenColumns.includes('reportDate') && (
                        <TableHeading
                          label={labelKey.reportDate}
                          sortedColumn={sortedColumn}
                          sortOrder={sortOrder}
                          handleSort={handleSort}
                          columnId='reportDate'
                        />
                      )}
                      {hiddenColumns.includes('syncSSToBCDateTime') && (
                        <TableHeading
                          label={labelKey.bcSyncDate}
                          sortedColumn={sortedColumn}
                          sortOrder={sortOrder}
                          handleSort={handleSort}
                          columnId='syncSSToBCDateTime'
                        />
                      )}

                      {hiddenColumns.includes('sS_ReportNo') && (
                        <TableHeading
                          label={labelKey.reportId}
                          columnId='sS_ReportNo'
                          className="ps-4"
                        />
                      )}
                      {hiddenColumns.includes('reportTitle') && (
                        <TableHeading
                          label={labelKey.reportTitle}
                          columnId='reportTitle'
                          className="ps-4"
                        />
                      )}
                      {hiddenColumns.includes('submitted_By') && (
                        <TableHeading
                          label={labelKey.user}
                          sortedColumn={sortedColumn}
                          sortOrder={sortOrder}
                          handleSort={handleSort}
                          columnId='submitted_By'
                        />
                      )}
                      {hiddenColumns.includes('amount') && (
                        <TableHeading
                          label={labelKey.totalAmount}
                          sortedColumn={sortedColumn}
                          sortOrder={sortOrder}
                          handleSort={handleSort}
                          columnId='amount'
                        />
                      )}
                      {hiddenColumns.includes('categoryName') && (
                        <TableHeading
                          label={labelKey.category}
                          sortedColumn={sortedColumn}
                          sortOrder={sortOrder}
                          handleSort={handleSort}
                          columnId='categoryName'
                        />
                      )}
                      {hiddenColumns.includes('paymentMethod') && (
                        <TableHeading
                          label={labelKey.paymentMethod}
                          sortedColumn={sortedColumn}
                          sortOrder={sortOrder}
                          handleSort={handleSort}
                          columnId='paymentMethod'
                        />
                      )}
                      {hiddenColumns.includes('reportStatus') && (
                        <TableHeading
                          label={labelKey.reportStatus}
                          sortedColumn={sortedColumn}
                          sortOrder={sortOrder}
                          handleSort={handleSort}
                          columnId='reportStatus'
                        />
                      )}
                      {hiddenColumns.includes('assignedToName') && (
                        <TableHeading
                          label={labelKey.assignTo}
                          sortedColumn={sortedColumn}
                          sortOrder={sortOrder}
                          handleSort={handleSort}
                          columnId='assignedToName'
                        />
                      )}
                      {hiddenColumns.includes('receipt') && (
                        <TableHeading
                          label={labelKey.receipt}
                          sortedColumn={sortedColumn}
                          sortOrder={sortOrder}
                          handleSort={handleSort}
                          columnId='receipt'
                        />
                      )}
                      {hiddenColumns.includes('chargeDepartmentCode') && (
                        <TableHeading
                          label={labelKey.departmentCode}
                          sortedColumn={sortedColumn}
                          sortOrder={sortOrder}
                          handleSort={handleSort}
                          columnId='chargeDepartmentCode'
                        />
                      )}
                      {hiddenColumns.includes('chargeDepartmentName') && (
                        <TableHeading
                          label={labelKey.department}
                          sortedColumn={sortedColumn}
                          sortOrder={sortOrder}
                          handleSort={handleSort}
                          columnId='chargeDepartmentName'
                        />
                      )}
                      {hiddenColumns.includes('splitToLocationsName') && (
                        <TableHeading
                          label={labelKey.splitToLocations}
                          sortedColumn={sortedColumn}
                          sortOrder={sortOrder}
                          handleSort={handleSort}
                          columnId='splitToLocationsName'
                        />
                      )}
                      {hiddenColumns.includes('chargeLocationCode') && (
                        <TableHeading
                          label={labelKey.locationCode}
                          sortedColumn={sortedColumn}
                          sortOrder={sortOrder}
                          handleSort={handleSort}
                          columnId='chargeLocationCode'
                        />
                      )}
                      {hiddenColumns.includes('chargeLocationName') && (
                        <TableHeading
                          label={labelKey.locationName}
                          sortedColumn={sortedColumn}
                          sortOrder={sortOrder}
                          handleSort={handleSort}
                          columnId='chargeLocationName'
                        />
                      )}
                      {hiddenColumns.includes('expenseLineItemNo') && (
                        <TableHeading
                          label={labelKey.ExpenseItemNo}
                          sortedColumn={sortedColumn}
                          sortOrder={sortOrder}
                          handleSort={handleSort}
                          columnId='expenseLineItemNo'
                        />
                      )}
                      {hiddenColumns.includes('reportSubmittedDate') && (
                        <TableHeading
                          label={labelKey.reportSubmittedDate}
                          sortedColumn={sortedColumn}
                          sortOrder={sortOrder}
                          handleSort={handleSort}
                          columnId='reportSubmittedDate'
                        />
                      )}
                      {hiddenColumns.includes('apApprovedDate') && (
                        <TableHeading
                          label={labelKey.apApprovedDate}
                          sortedColumn={sortedColumn}
                          sortOrder={sortOrder}
                          handleSort={handleSort}
                          columnId='apApprovedDate'
                        />
                      )}
                      {hiddenColumns.includes('paidDate') && (
                        <TableHeading
                          label={labelKey.paidDate}
                          sortedColumn={sortedColumn}
                          sortOrder={sortOrder}
                          handleSort={handleSort}
                          columnId='paidDate'
                        />
                      )}
                      {hiddenColumns.includes('transactionSource') && (
                        <TableHeading
                          label={labelKey.source}
                          sortedColumn={sortedColumn}
                          sortOrder={sortOrder}
                          handleSort={handleSort}
                          columnId='transactionSource'
                        />
                      )}

                      {/* {hiddenColumns.includes('vendorName') && (
                        <TableHeading
                          label={labelKey.vendor}
                          sortedColumn={sortedColumn}
                          sortOrder={sortOrder}
                          handleSort={handleSort}
                          columnId='vendorName'
                        />
                      )} */}


                      <th className='text-end rounded-end pe-2 border-0'>

                        <TableSettingMenu>
                          <TextField
                            rightLabel={labelKey.itemDate}
                            type="checkbox"
                            onChange={() => handleToggleColumn("itemTransactionDate")}
                            checked={hiddenColumns.includes('itemTransactionDate')}
                            fieldClass='mb-4'
                          />

                          <TextField
                            rightLabel={labelKey.reportDate}
                            type="checkbox"
                            onChange={() => handleToggleColumn("reportDate")}
                            checked={hiddenColumns.includes('reportDate')}
                            fieldClass='mb-4'
                          />
                          <TextField
                            rightLabel={labelKey.bcSyncDate}
                            type="checkbox"
                            onChange={() => handleToggleColumn("syncSSToBCDateTime")}
                            checked={hiddenColumns.includes('syncSSToBCDateTime')}
                            fieldClass='mb-4'
                          />
                          <TextField
                            rightLabel={labelKey.reportId}
                            type="checkbox"
                            onChange={() => handleToggleColumn("sS_ReportNo")}
                            checked={hiddenColumns.includes('sS_ReportNo')}
                            fieldClass='mb-4'
                          />
                          <TextField
                            rightLabel={labelKey.reportTitle}
                            type="checkbox"
                            onChange={() => handleToggleColumn("reportTitle")}
                            checked={hiddenColumns.includes('reportTitle')}
                            fieldClass='mb-4'
                          />
                          <TextField
                            rightLabel={labelKey.user}
                            type="checkbox"
                            onChange={() => handleToggleColumn("submitted_By")}
                            checked={hiddenColumns.includes('submitted_By')}
                            fieldClass='mb-4'
                          />
                          <TextField
                            rightLabel={labelKey.totalAmount}
                            type="checkbox"
                            onChange={() => handleToggleColumn("amount")}
                            checked={hiddenColumns.includes('amount')}
                            fieldClass='mb-4'
                          />
                          <TextField
                            rightLabel={labelKey.category}
                            type="checkbox"
                            onChange={() => handleToggleColumn("categoryName")}
                            checked={hiddenColumns.includes('categoryName')}
                            fieldClass='mb-4'
                          />
                          <TextField
                            rightLabel={labelKey.paymentMethod}
                            type="checkbox"
                            onChange={() => handleToggleColumn("paymentMethod")}
                            checked={hiddenColumns.includes('paymentMethod')}
                            fieldClass='mb-4'
                          />
                          <TextField
                            rightLabel={labelKey.reportStatus}
                            type="checkbox"
                            onChange={() => handleToggleColumn("reportStatus")}
                            checked={hiddenColumns.includes('reportStatus')}
                            fieldClass='mb-4'
                          />
                          <TextField
                            rightLabel={labelKey.assignTo}
                            type="checkbox"
                            onChange={() => handleToggleColumn("assignedToName")}
                            checked={hiddenColumns.includes('assignedToName')}
                            fieldClass='mb-4'
                          />
                          <TextField
                            rightLabel={labelKey.receipt}
                            type="checkbox"
                            onChange={() => handleToggleColumn("receipt")}
                            checked={hiddenColumns.includes('receipt')}
                            fieldClass='mb-4'
                          />
                          <TextField
                            rightLabel={labelKey.departmentCode}
                            type="checkbox"
                            onChange={() => handleToggleColumn("chargeDepartmentCode")}
                            checked={hiddenColumns.includes('chargeDepartmentCode')}
                            fieldClass='mb-4'
                          />
                          <TextField
                            rightLabel={labelKey.department}
                            type="checkbox"
                            onChange={() => handleToggleColumn("chargeDepartmentName")}
                            checked={hiddenColumns.includes('chargeDepartmentName')}
                            fieldClass='mb-4'
                          />
                          <TextField
                            rightLabel={labelKey.splitToLocations}
                            type="checkbox"
                            onChange={() => handleToggleColumn("splitToLocationsName")}
                            checked={hiddenColumns.includes('splitToLocationsName')}
                            fieldClass='mb-4'
                          />
                          <TextField
                            rightLabel={labelKey.locationCode}
                            type="checkbox"
                            onChange={() => handleToggleColumn("chargeLocationCode")}
                            checked={hiddenColumns.includes('chargeLocationCode')}
                            fieldClass='mb-4'
                          />
                          <TextField
                            rightLabel={labelKey.locationName}
                            type="checkbox"
                            onChange={() => handleToggleColumn("chargeLocationName")}
                            checked={hiddenColumns.includes('chargeLocationName')}
                            fieldClass='mb-4'
                          />
                          <TextField
                            rightLabel={labelKey.ExpenseItemNo}
                            type="checkbox"
                            onChange={() => handleToggleColumn("expenseLineItemNo")}
                            checked={hiddenColumns.includes('expenseLineItemNo')}
                            fieldClass='mb-4'
                          />

                          <TextField
                            rightLabel={labelKey.reportSubmittedDate}
                            type="checkbox"
                            onChange={() => handleToggleColumn("reportSubmittedDate")}
                            checked={hiddenColumns.includes('reportSubmittedDate')}
                            fieldClass='mb-4'
                          />
                          <TextField
                            rightLabel={labelKey.apApprovedDate}
                            type="checkbox"
                            onChange={() => handleToggleColumn("apApprovedDate")}
                            checked={hiddenColumns.includes('apApprovedDate')}
                            fieldClass='mb-4'
                          />
                          <TextField
                            rightLabel={labelKey.paidDate}
                            type="checkbox"
                            onChange={() => handleToggleColumn("paidDate")}
                            checked={hiddenColumns.includes('paidDate')}
                            fieldClass='mb-4'
                          />
                          <TextField
                            rightLabel={labelKey.source}
                            type="checkbox"
                            onChange={() => handleToggleColumn("transactionSource")}
                            checked={hiddenColumns.includes('transactionSource')}
                            fieldClass='mb-4'
                          />

                          <TextField
                            rightLabel={labelKey.source}
                            type="checkbox"
                            onChange={() => handleToggleColumn("transactionSource")}
                            checked={hiddenColumns.includes('transactionSource')}
                            fieldClass='mb-4'
                          />


                          {/* <TextField
                            rightLabel={labelKey.vendor}
                            type="checkbox"
                            onChange={() => handleToggleColumn("vendorName")}
                            checked={hiddenColumns.includes('vendorName')}
                            fieldClass='mb-4'
                          /> */}

                        </TableSettingMenu>
                      </th>

                    </tr>
                  </thead>
                  <tbody>
                    {!isLoading &&
                      <>
                        {data?.result?.data?.length > 0 ? (
                          <>
                            {data?.result?.data?.map((data: any, index: any) => (
                              <tr key={index}>
                                {/* <td className='ps-5'>{(page - 1) * pageSize + index + 1}</td> */}
                                {hiddenColumns.includes('itemTransactionDate') && (
                                  <td className='ps-3'>
                                    <DateComp formattedDate={data.itemTransactionDate} />
                                  </td>
                                )}
                                {hiddenColumns.includes('reportDate') && (
                                  <td>
                                    <DateComp formattedDate={data.reportDate} />
                                  </td>
                                )}
                                {hiddenColumns.includes('syncSSToBCDateTime') && (
                                  <td>
                                    <DateComp formattedDate={data.syncSSToBCDateTime} />
                                  </td>
                                )}
                                {hiddenColumns.includes('sS_ReportNo') && (
                                  <td className='ps-5'>{data?.sS_ReportNo}</td>
                                )}
                                {hiddenColumns.includes('reportTitle') && (
                                  <td className='ps-5'>{data?.reportTitle}</td>
                                )}
                                {hiddenColumns.includes('submitted_By') && (
                                  <td>
                                    <div className='d-flex align-items-center'>
                                      <div className='symbol symbol-40px symbol-circle me-3'>
                                        <div className="symbol-label fs-3 bg-light-info text-info text-capitalize">{data?.submitted_By?.charAt(0)}</div>
                                      </div>
                                      <div className='d-flex justify-content-start flex-column'>
                                        <p className='text-capitalize mb-0'>
                                          {data?.submitted_By}
                                        </p>
                                        {/* <span className='text-muted fw-semibold text-muted d-block fs-7 text-lowercase'>
                                            {data?.submitted_By?.email}
                                          </span> */}
                                      </div>
                                    </div>
                                  </td>
                                )}
                                {hiddenColumns.includes('amount') && (
                                  <td><CurrencyComp amount={data?.amount} /></td>
                                )}
                                {hiddenColumns.includes('categoryName') && (
                                  <td>{data?.categoryName} </td>
                                )}
                                {hiddenColumns.includes('paymentMethod') && (
                                  <td>{data?.paymentMethod}</td>
                                )}
                                {hiddenColumns.includes('reportStatus') && (
                                  <td>
                                    {data?.expenseStatusId === constraintConfig.expenseStatus.drafted ?
                                      <span className="badge badge-light-primary fs-7 fw-semibold">{data.reportStatus}</span> :
                                      data?.expenseStatusId === constraintConfig.expenseStatus.pendingForApproval ?
                                        <span className="badge badge-light-info fs-7 fw-semibold">{data.reportStatus}</span> :
                                        // data?.expenseStatusId === constraintConfig.expenseStatus.clarification ?
                                        //     <span className="badge badge-light-warning fs-7 fw-semibold">{labelKey.clarification}</span> :
                                        data?.expenseStatusId === constraintConfig.expenseStatus.approved ?
                                          <span className="badge badge-light-success fs-7 fw-semibold">{data.reportStatus}</span> :
                                          data?.expenseStatusId === constraintConfig.expenseStatus.rejected ?
                                            <span className="badge badge-light-danger fs-7 fw-semibold">{data.reportStatus}</span> :
                                            data?.expenseStatusId === constraintConfig.expenseStatus.apApproved ?
                                              <span className="badge badge-light-success fs-7 fw-semibold">{data.reportStatus}</span> :
                                              data?.expenseStatusId === constraintConfig.expenseStatus.processing ?
                                                <span className="badge badge-light-primary fs-7 fw-semibold">{data.reportStatus}</span> :
                                                data?.expenseStatusId === constraintConfig.expenseStatus.paid ?
                                                  <span className="badge badge-light-success fs-7 fw-semibold">{data.reportStatus}</span> :
                                                  <span className="badge badge-light-primary fs-7 fw-semibold">{data.reportStatus}</span>
                                    }
                                  </td>
                                )}
                                {hiddenColumns.includes('assignedToName') && (
                                  <td>
                                    {data?.assignedToName &&
                                      <div className='d-flex align-items-center'>
                                        <div className='symbol symbol-40px symbol-circle me-3'>
                                          <div className="symbol-label fs-3 bg-light-info text-info text-capitalize">{data?.assignedToName?.charAt(0)}</div>
                                        </div>
                                        <div className='d-flex justify-content-start flex-column'>
                                          <p className='text-capitalize mb-0'>
                                            {data?.assignedToName}
                                          </p>
                                          {/* <span className='text-muted fw-semibold text-muted d-block fs-7 text-lowercase'>
                                            {data?.submitted_By?.email}
                                          </span> */}
                                        </div>
                                      </div>
                                    }
                                  </td>
                                )}
                                {hiddenColumns.includes('receipt') && (
                                  <td>
                                    <Tooltip id="upload-reciept" place="top" />
                                    {data?.receipt === null || data?.receipt === "null" || data?.receipt === "undefined" || data?.receipt === "" || data?.receipt === "Receipt is not available" ? (
                                      null
                                    ) : <FaEye className='ms-7' onClick={() => openPdfInBrowserOrLightbox(data?.receipt)} />}
                                    {lightboxOpen && (
                                      <Lightbox
                                        mainSrc={lightboxData.imageURL}
                                        onCloseRequest={() => setLightboxOpen(false)}
                                        imageCaption="Attachment"
                                        enableZoom={true}
                                        imagePadding={50}
                                      />
                                    )}
                                  </td>
                                )}
                                {hiddenColumns.includes('chargeDepartmentCode') && (
                                  <td>{data?.chargeDepartmentCode}</td>
                                )}
                                {hiddenColumns.includes('chargeDepartmentName') && (
                                  <td>{data?.chargeDepartmentName}</td>
                                )}
                                {hiddenColumns.includes('splitToLocationsName') && (
                                  <td>{data?.splitToLocationsName}</td>
                                )}
                                {hiddenColumns.includes('chargeLocationCode') && (
                                  <td>{data?.chargeLocationCode}</td>
                                )}

                                {hiddenColumns.includes('chargeLocationName') && (
                                  <td>{data.chargeLocationName}</td>
                                )}
                                {hiddenColumns.includes('expenseLineItemNo') && (
                                  <td>{data.expenseLineItemNo}</td>
                                )}
                                {hiddenColumns.includes('reportSubmittedDate') && (
                                  <td>
                                    <DateComp formattedDate={data.reportSubmittedDate} />
                                  </td>
                                )}
                                {hiddenColumns.includes('apApprovedDate') && (
                                  <td>
                                    <DateComp formattedDate={data.apApprovedDate} />
                                  </td>
                                )}
                                {hiddenColumns.includes('paidDate') && (
                                  <td>
                                    <DateComp formattedDate={data.paidDate} />
                                  </td>
                                )}

                                {hiddenColumns.includes('transactionSource') && (
                                  <td>
                                    <Tooltip id="web-source" place="top" />
                                    <Tooltip id="mobile-source" place="top" />
                                    <Tooltip id="bank-source" place="top" />

                                    {data?.recordSourceID === constraintConfig.recordSourceId.WebApp ?
                                      <KTSVG path="/media/icons/figmaIcons/Web.svg" className='svg-icon-1 ms-4'
                                        data-tooltip-id="web-source" data-tooltip-content='Website' />
                                      :
                                      data?.recordSourceID === constraintConfig.recordSourceId.Mobile ?
                                        <KTSVG path="/media/icons/figmaIcons/Mobile.svg" className='svg-icon-1 ms-4'
                                          data-tooltip-id="mobile-source" data-tooltip-content='Mobile' /> :
                                        data?.recordSourceID === constraintConfig.recordSourceId.BankFeed ?
                                          <KTSVG path="/media/icons/figmaIcons/Bank.svg" className='svg-icon-1 ms-4'
                                            data-tooltip-id="bank-source" data-tooltip-content='Bank' />
                                          : null}
                                  </td>
                                )}
                                {/* {hiddenColumns.includes('vendorName') && (
                                  <td colSpan={2}>{data.vendorName}</td>
                                )} */}
                                <td>
                                  <Tooltip id={labelKey.itemDetail} place="top" />
                                  <div
                                    data-tooltip-id={labelKey.itemDetail} data-tooltip-content={labelKey.itemDetail}
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                    onClick={() => {
                                      expenseItemByIdTrigger({ expenseItemId: data?.expenseLineItemNo });
                                      expenseItemModalHandler();
                                    }}
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/arrows/arr064.svg'
                                      className='svg-icon-3'
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </>
                        ) :
                          (
                            <tr>
                              <td colSpan={20}>
                                <NoRecordFound />
                              </td>
                            </tr>
                          )
                        }
                      </>
                    }
                    {isLoading && (
                      <tr>
                        <td colSpan={20} className="text-center">
                          <LoadingComponent />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <Pagination
              totalResult={data?.result?.totalRecords === 0 ? 0 : ((data?.result?.pageNumber - 1) * data?.result?.pageSize) + 1}
              toResult={data?.result?.totalRecords === 0 ? 0 : Math.min(data?.result?.pageNumber * data?.result?.pageSize, data?.result?.totalRecords)}
              ofResult={data?.result?.totalRecords}
              onChange={handlePageSizeChange}
              pageSize={pageSize}
              onPageChange={handlePageClick}
              pageCount={data?.result?.totalPages || 0}
              currentPage={page}
            />
          </div>
        </div>
      </div >
    </>
  )
}

export default ExportExpenseReports