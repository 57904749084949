import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { KTSVG } from '../../../../_metronic/helpers';
import DateComp from '../../../../components/dateComponent/DateComp';
import Pagination from '../../../../components/pagination/Pagination';
import SearchBarComponent from '../../../../components/searchBar/SearchBarComponent';
import { useGetCatagoryBudgetAllocationQuery } from '../../../../services/BudgetApi';
import labelKey from '../../../localization/label.json';
import AddUpdateCategoryBudgetModal from './AddUpdateCategoryBudgetModal';
import ActiveCategoryBudgetConfirmationModal from './ActiveCategoryBudgetConfirmationModal';
import TableHeading from '../../../../components/tableHeading/TableHeading';
import TextField from '../../../../components/textFields/TextField';
import TableSettingMenu from '../../../../components/tableHeading/TableSettingMenu';
import NoRecordFound from '../../../../components/noRecord/NoRecordFound';
import { LoadingComponent } from '../../../../components/loadingComponent/LoadingComponent';
import useColumnVisibility from '../../../customHooks/useColumnVisibility';
import CurrencyComp from '../../../../components/currencyComponent/CurrencyComp';

const CategoryBudgetPage = () => {
    const { hiddenColumns, handleToggleColumn } = useColumnVisibility({ pageName: 'categoryBudgeting' });
    const [searchStr, setSearchStr] = useState(''); // debounce search
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10);
    const [immediateSearchStr, setImmediateSearchStr] = useState('');
    const [isChecked, setIsChecked] = useState(false)

    const { data, isLoading, refetch } = useGetCatagoryBudgetAllocationQuery({
        pageNumber: page,
        pageSize: pageSize,
        searchstr: searchStr,
    })
    const handlePageSizeChange = (e: any) => {
        const newSize = parseInt(e.target.value);
        setPageSize(newSize);
        setPage(1); // Reset page number to 1
    };
    // pagination
    const handlePageClick = (e: any) => {
        setPage(e.selected + 1);
    };
    //per page record
    const handlePageRecords = (e: any) => {
        setPage(1); // Reset the page to 1
    };
    const handleSearchChange = (e: any) => {
        const { value } = e.target;
        setImmediateSearchStr(value); // Update immediate search state

        delayedSearch(value); // Update debounced search state
        setPage(1);
    };

    const delayedSearch = useCallback(
        debounce((searchValue) => {
            setSearchStr(searchValue);
        }, 500),
        []
    );
    useEffect(() => {
        refetch();
        if (searchStr === "") {
            refetch();
        }
    }, [searchStr]);

    const [showInnerTable, setShowInnerTable] = useState(null);
    const handleRowClick = (rowId: any) => {
        if (showInnerTable === rowId) {
            setShowInnerTable(null);
        } else {
            setShowInnerTable(rowId);
        }
    };
    const [showAddBudgetModal, setShowAddBudgetModal] = useState(false)
    const [editBudgetData, setEditBudgetData] = useState({})
    const addBudgetModalHandler = () => {
        setShowAddBudgetModal((prev) => !prev);
    };

    const [showCategoryBudgetActiveConfirmationModal, setShowCategoryBudgetActiveConfirmationModal] = useState(false)
    const categoryBudgetActiveConfirmationHandler = () => {
        setShowCategoryBudgetActiveConfirmationModal((prev) => !prev);
    };

    // const [prevModalState, setPrevModalState] = useState({
    //     showAddBudgetModal: false,
    //     showCategoryBudgetActiveConfirmationModal: false,
    // });

    // // Update previous modal state whenever the modals change
    // useEffect(() => {
    //     setPrevModalState({
    //         showAddBudgetModal: showAddBudgetModal,
    //         showCategoryBudgetActiveConfirmationModal: showCategoryBudgetActiveConfirmationModal,
    //     });
    // }, [showAddBudgetModal, showCategoryBudgetActiveConfirmationModal]);

    // // Check for modal closing and trigger refetch
    // useEffect(() => {
    //     if (
    //         (prevModalState.showAddBudgetModal && !showAddBudgetModal) ||
    //         (prevModalState.showCategoryBudgetActiveConfirmationModal && !showCategoryBudgetActiveConfirmationModal)

    //     ) {
    //         refetch();
    //     }
    // }, [
    //     showAddBudgetModal,
    //     showCategoryBudgetActiveConfirmationModal,
    //     prevModalState.showAddBudgetModal,
    //     prevModalState.showCategoryBudgetActiveConfirmationModal,
    //     refetch,
    // ]);


    return (
        <>
            <AddUpdateCategoryBudgetModal
                show={showAddBudgetModal}
                handleClose={() => setShowAddBudgetModal(false)}
                budgetData={editBudgetData}
                refetch={refetch} />
            <ActiveCategoryBudgetConfirmationModal
                show={showCategoryBudgetActiveConfirmationModal}
                handleClose={() => setShowCategoryBudgetActiveConfirmationModal(false)}
                data={editBudgetData}
                isChecked={isChecked}
                refetch={refetch} />
            <div className={`card`}>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                    <div className='card-title'>
                        <SearchBarComponent
                            placeholder='Name'
                            value={immediateSearchStr}
                            onChange={handleSearchChange}
                        />
                    </div>
                    <div className='d-flex align-items-center ms-auto'>
                        <div className='card-toolbar'>
                            <button className='btn btn-sm btn-light-primary'
                                onClick={() => {
                                    setEditBudgetData(true);
                                    addBudgetModalHandler();
                                }}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                                {labelKey.addCategoryBudget}
                            </button>
                        </div>
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive table-height'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    {hiddenColumns.includes('sr') && (
                                        <TableHeading
                                            label={labelKey.sr}
                                            columnId='sr'
                                            className="ps-4"
                                        />
                                    )}
                                    {hiddenColumns.includes('name') && (
                                        <TableHeading
                                            label={labelKey.name}
                                            columnId='name'
                                        />
                                    )}

                                    {hiddenColumns.includes('startDate') && (
                                        <TableHeading
                                            label={labelKey.startDate}
                                            columnId='startDate'
                                        />
                                    )}
                                    {hiddenColumns.includes('endDate') && (
                                        <TableHeading
                                            label={labelKey.endDate}
                                            columnId='endDate'
                                        />
                                    )}
                                    {hiddenColumns.includes('users') && (
                                        <TableHeading
                                            label={labelKey.users}
                                            columnId='users'
                                            className="w-500px"
                                        />
                                    )}
                                    {hiddenColumns.includes('status') && (
                                        <TableHeading
                                            label={labelKey.status}
                                            columnId='status'
                                        />
                                    )}
                                    <th className='text-end rounded-end pe-2 border-0'>
                                        {hiddenColumns.includes('actions') && (
                                            <>
                                                {labelKey.actions}
                                            </>
                                        )}
                                        <TableSettingMenu>
                                            <TextField
                                                rightLabel={labelKey.sr}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("sr")}
                                                checked={hiddenColumns.includes('sr')}
                                                fieldClass='mb-4'
                                            />

                                            <TextField
                                                rightLabel={labelKey.name}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("name")}
                                                checked={hiddenColumns.includes('name')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.startDate}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("startDate")}
                                                checked={hiddenColumns.includes('startDate')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.endDate}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("endDate")}
                                                checked={hiddenColumns.includes('endDate')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.users}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("users")}
                                                checked={hiddenColumns.includes('users')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.status}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("status")}
                                                checked={hiddenColumns.includes('status')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.actions}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("actions")}
                                                checked={hiddenColumns.includes('actions')}
                                                fieldClass='mb-4'
                                            />
                                        </TableSettingMenu>
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {!isLoading &&
                                    <>
                                        {data?.result?.data?.length > 0 ?
                                            (
                                                <>
                                                    {data?.result?.data?.map((data: any, index: any) => (
                                                        <>
                                                            <Tooltip id="expand-row" place="bottom" />
                                                            <tr key={index}
                                                                data-tooltip-id="expand-row" data-tooltip-content='Click here to expand'
                                                                className={`cursor-pointer ${showInnerTable === data?.catagoryBudgetId ? 'highlighted-row' : ''}`}
                                                                onClick={() => {
                                                                    handleRowClick(data?.catagoryBudgetId);
                                                                }}>
                                                                {hiddenColumns.includes('sr') && (
                                                                    <td className='ps-4'> {(page - 1) * pageSize + index + 1}</td>
                                                                )}
                                                                {hiddenColumns.includes('name') && (
                                                                    <td>{data?.name}</td>
                                                                )}
                                                                {hiddenColumns.includes('startDate') && (
                                                                    <td><DateComp formattedDate={data?.startDate} /></td>
                                                                )}
                                                                {hiddenColumns.includes('endDate') && (
                                                                    <td><DateComp formattedDate={data?.endDate} /></td>
                                                                )}
                                                                {hiddenColumns.includes('users') && (
                                                                    <td>
                                                                        <div className='d-flex gap-2 overflow-auto sm-scroll w-500px'>
                                                                            {data?.userAccount?.map((UserNames: any, index1: any) => (
                                                                                <div key={index1}>
                                                                                    <span className="badge badge-light-primary fs-7 fw-semibold">{UserNames?.name}</span>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </td>
                                                                )}
                                                                {hiddenColumns.includes('status') && (
                                                                    <td>
                                                                        <div className='d-flex align-items-center gap-2'>
                                                                            {data?.isActive === true ? <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.active}</span> :
                                                                                <span className="badge badge-light-danger fs-7 fw-semibold">{labelKey.inActive}</span>}
                                                                            <div className="form-check form-switch">
                                                                                <input
                                                                                    className="form-check-input cursor-pointer"
                                                                                    type="checkbox"
                                                                                    role="switch"
                                                                                    id={`flexSwitchCheckChecked-${data?.dptLocId}`}
                                                                                    checked={data?.isActive}
                                                                                    onChange={(e) => {
                                                                                        setIsChecked(e.target.checked);
                                                                                        setEditBudgetData(data);
                                                                                        categoryBudgetActiveConfirmationHandler();
                                                                                    }}
                                                                                />
                                                                                <label className="form-check-label" htmlFor={`flexSwitchCheckChecked-${data?.dptLocId}`}></label>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                )}
                                                                {hiddenColumns.includes('actions') && (
                                                                    <td className='w-100px'>
                                                                        <div className='d-flex align-items-center justify-content-end gap-2' >
                                                                            <Tooltip id="update-budget" place="bottom" />
                                                                            <div
                                                                                data-tooltip-id="update-budget" data-tooltip-content={labelKey.updateCategoryBudget}
                                                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                                                onClick={() => {
                                                                                    setEditBudgetData(data);
                                                                                    addBudgetModalHandler();
                                                                                }}
                                                                            >
                                                                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                )}
                                                            </tr>
                                                            {showInnerTable === data?.catagoryBudgetId && (
                                                                <tr className={`inner-row ${showInnerTable === data?.catagoryBudgetId ? 'highlighted-row' : ''}`}>
                                                                    <td className='pt-0 px-3' colSpan={8}>
                                                                        <div className="table-responsive">
                                                                            <table className="table align-middle gs-0 gy-4">
                                                                                <thead>
                                                                                    <tr className='fw-bold text-muted bg-light'>
                                                                                        <th className='ps-4 w-50'>{labelKey.category}</th>
                                                                                        <th>{labelKey.budget}</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {data?.catagoryBudgetAllocation?.length > 0 ? (
                                                                                        <>

                                                                                            {data?.catagoryBudgetAllocation?.map((categoryBudet: any, index: any) => (
                                                                                                <tr key={index}>
                                                                                                    <td className='ps-5'>{categoryBudet?.catagoryName}</td>
                                                                                                    <td><CurrencyComp amount={categoryBudet?.budget} /></td>

                                                                                                </tr>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <tr>
                                                                                            <td colSpan={3}>
                                                                                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                                                                    {labelKey.noMatchingRecordsFound}
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </>
                                                    ))}
                                                </>)
                                            :
                                            (
                                                <tr>
                                                    <td colSpan={7}>
                                                        <NoRecordFound />
                                                    </td>
                                                </tr>
                                            )}
                                    </>
                                }
                                {isLoading && (
                                    <tr>
                                        <td colSpan={7} className="text-center">
                                            <LoadingComponent />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        totalResult={data?.result?.totalRecords === 0 ? 0 : ((data?.result?.pageNumber - 1) * data?.result?.pageSize) + 1}
                        toResult={data?.result?.totalRecords === 0 ? 0 : Math.min(data?.result?.pageNumber * data?.result?.pageSize, data?.result?.totalRecords)}
                        ofResult={data?.result?.totalRecords}
                        onChange={handlePageSizeChange}
                        pageSize={pageSize}
                        onPageChange={handlePageClick}
                        pageCount={data?.result?.totalPages || 0}
                        currentPage={page}
                    />
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
        </>
    )
}

export default CategoryBudgetPage
