import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
// import Select from 'react-select';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { KTSVG } from '../../../_metronic/helpers';
import CurrencyComp from '../../../components/currencyComponent/CurrencyComp';
import DateComp from '../../../components/dateComponent/DateComp';
import { LoadingComponent } from '../../../components/loadingComponent/LoadingComponent';
import NoRecordFound from '../../../components/noRecord/NoRecordFound';
import Pagination from '../../../components/pagination/Pagination';
import TableHeading from '../../../components/tableHeading/TableHeading';
import TableSettingMenu from '../../../components/tableHeading/TableSettingMenu';
import TextField from '../../../components/textFields/TextField';
import { constraintConfig } from '../../../constraintConfig';
import { selectAuth } from '../../../features/authSlice';
import { useAppSelector } from '../../../hooks';
import { useGetAllUsersManagerAdminQuery } from '../../../services/GeneralApi';
import { useGetExpensesReportByUserV2Query } from '../../../services/ReportAndAnalyticsApi';
import useColumnVisibility from '../../customHooks/useColumnVisibility';
import labelKey from "../../localization/label.json";

import { components } from 'react-select';
import DateRangeComp from '../../../components/dateComponent/DateRangeComp';
import RefreshComponent from '../../../components/refreshComponent/RefreshComponent';
import SearchSelect from '../../../components/textFields/SearchSelect';
import { useLazyGetExpenseItemByExpenseIdQuery } from '../../../services/ExpenseApi';
import ExpenseDetailModal from '../expenseItems/ExpenseDetailModal';
import DownloadReport from '../reportAnalytics/DownloadReport';
import { PageTitle } from '../../../_metronic/layout/core';
import { Tooltip } from 'react-tooltip';
import ImpersonateUserModal from '../users/ImpersonateUserModal';

const { Option } = components;
type Option = {
    value: string;
    label: string;
};

// Custom Option component to add checkboxes
const CheckboxOption = (props: any) => {
    return (
        <Option {...props} className='d-flex align-items-start gap-2'>
            <input
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}
            />{" "}
            <label>{props.label}</label>
        </Option>
    );
};

const ExpenseSearch = () => {
    const currentDate = moment().format('MM/DD/YYYY');
    const last30Days = moment().subtract(29, 'days').format('MM/DD/YYYY');
    const { hiddenColumns, handleToggleColumn } = useColumnVisibility({ pageName: 'searchExpense' });
    const { userAccountID, roleID } = useAppSelector(selectAuth);
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10);
    const [searchReporID, setSearchReporID] = useState('');
    const [localSearchReportID, setLocalSearchReportID] = useState('');

    const [sortBy, setSortBy] = useState("createdDate");
    const [sortOrder, setSortOrder] = useState("desc");
    const [filterByStartDate, setFilterByStartDate] = useState(last30Days);
    const [filterByEndDate, setFilterByEndDate] = useState(currentDate);
    const [sortedColumn, setSortedColumn] = useState("createdDate");
    const [filterByUserIds, setFilterByUserIds] = useState("");
    const [localFilterByStartDate, setLocalFilterByStartDate] = useState(last30Days);
    const [localFilterByEndDate, setLocalFilterByEndDate] = useState(currentDate);
    const [localFilterByUserIds, setLocalFilterByUserIds] = useState("");
    const [isApplyingFilters, setIsApplyingFilters] = useState(false);


    const { data: getUserByManager, refetch: userByManagerRefetch } = useGetAllUsersManagerAdminQuery('')
    const [expenseItemByIdTrigger, { data: expenseItemData, isFetching }] = useLazyGetExpenseItemByExpenseIdQuery({})

    const { data, isFetching: isLoading, refetch, isSuccess } = useGetExpensesReportByUserV2Query({
        reportFilter: 2,
        userAccountId: userAccountID,
        pageNumber: page,
        pageSize: pageSize,
        sortBy: sortBy,
        sortOrder: sortOrder,
        searchStr: searchReporID,
        startDate: filterByStartDate,
        endDate: filterByEndDate,
        dptIds: null,
        userIds: filterByUserIds ? filterByUserIds : null,
        locationIds: null,
    })



    const handlePageSizeChange = (e: any) => {
        const newSize = parseInt(e.target.value);
        setPageSize(newSize);
        setPage(1); // Reset page number to 1
    };
    // pagination
    const handlePageClick = (e: any) => {
        setPage(e.selected + 1);
    };

    const handleSort = (property: string) => {
        setSortBy(property);
        if (sortedColumn === property) {
            setSortOrder(prevSortOrder => (prevSortOrder === "asc" ? "desc" : "asc"));
        } else {
            setSortedColumn(property);
            setSortOrder("asc");
        }
    };

    //per page record
    const handlePageRecords = (e: any) => {
        setPage(1); // Reset the page to 1
    };



    const handleDateRangeChange = (event: any, picker: any) => {
        const startDateFormatted = moment(picker.startDate).format('MM-DD-YYYY');
        const endDateFormatted = moment(picker.endDate).format('MM-DD-YYYY');

        setLocalFilterByStartDate(startDateFormatted);
        setLocalFilterByEndDate(endDateFormatted);
    };
    const handleCancelDateSelection = (event: any, picker: any) => {
        // Reset the state to empty strings
        setFilterByStartDate("");
        setFilterByEndDate("");
        setLocalFilterByStartDate("");
        setLocalFilterByEndDate("");
    };

    const handleSearchReportId = (e: any) => {
        setLocalSearchReportID(e.target.value);
    };
    const handleFilterByUser = (selectedOption: any) => {
        // setLocalFilterByExpType(selectedOption.value);
        setLocalFilterByUserIds(selectedOption.value);

    };

    const applyFilters = () => {
        setIsApplyingFilters(true);
        setFilterByStartDate(localFilterByStartDate);
        setFilterByEndDate(localFilterByEndDate);
        setFilterByUserIds(localFilterByUserIds)
        setSearchReporID(localSearchReportID);
        setPage(1);
    };
    const resetFilters = () => {
        setFilterByStartDate(last30Days);
        setFilterByEndDate(currentDate);
        setFilterByUserIds('')

        setLocalFilterByStartDate(last30Days);
        setLocalFilterByEndDate(currentDate);
        setLocalFilterByUserIds('')
        setLocalSearchReportID('')
        setSearchReporID('')

    }

    const debouncedRefetch = debounce(() => {
        refetch().finally(() => {
            setIsApplyingFilters(false);
        });
    }, 300);
    useEffect(() => {
        if (isApplyingFilters) {
            debouncedRefetch();
        }
    }, [isApplyingFilters]);

    useEffect(() => {
        if (
            sortOrder === "" ||
            sortBy === "") {
            refetch();
        }
    }, [sortOrder, sortBy]);

    const [showExpenseDetailModal, setShowExpenseDetailModal] = useState(false)
    const [expenseDetailData, setExpenseDetailData] = useState({});

    const expenseDetailHandler = () => {
        setShowExpenseDetailModal((prev) => !prev);
    };
    const [impersonateUserModal, setImpersonateUserModal] = useState(false);
    const [impersonateData, setImpersonateData] = useState({});
    const impersonateHandler = () => {
        setImpersonateUserModal((prev) => !prev);
    };

    const handleRefresh = () => {
        // Trigger refetch after state updates are done
        setTimeout(() => {
            refetch()
                .then(() => {
                    if (isSuccess) {
                        toast.success('Data Refresh successfully!');
                    }
                });
        }, 0);
    };
    return (
        <>
            <ImpersonateUserModal show={impersonateUserModal} handleClose={() => setImpersonateUserModal(false)} data={impersonateData} />
            <ExpenseDetailModal show={showExpenseDetailModal} handleClose={() => setShowExpenseDetailModal(false)} data={expenseDetailData} expenseItems={expenseItemData?.result} isFetching={isFetching} />
            <PageTitle>{labelKey.searchExpense}</PageTitle>
            <div className='card'>
                <div className='card-body pb-5 pt-0'>
                    <div className="row pt-3">
                        <div className="col-md-4 col-lg-3 col-xl-2 mb-5">
                            <SearchSelect
                                label='User:'
                                options={[
                                    // { value: '0', label: 'All' }, // Add the empty option
                                    ...(getUserByManager?.result || []).map((option: any) => ({
                                        value: option.id,
                                        label: option?.value,
                                    })),
                                ]}
                                placeholder='Select User'
                                value={localFilterByUserIds && localFilterByUserIds !== ''
                                    ? {
                                        value: localFilterByUserIds,
                                        label: getUserByManager?.result.find((option: any) => option.id === localFilterByUserIds)?.value
                                    }
                                    : null
                                }
                                onChange={handleFilterByUser}
                            />
                        </div>
                        <div className='col-md-4 col-lg-3 col-xl-2 mb-5'>
                            <TextField
                                label='Report ID:'
                                placeholder='Search Expense with Report ID'
                                value={localSearchReportID}
                                onChange={handleSearchReportId}
                            />
                        </div>
                        <div className='col-md-4 col-lg-3 col-xl-2 mb-5'>
                            <DateRangeComp
                                startDate={localFilterByStartDate || moment().subtract(6, 'days')}
                                endDate={localFilterByEndDate || moment()}
                                onApply={handleDateRangeChange} onCancel={handleCancelDateSelection}
                                value={localFilterByStartDate && localFilterByEndDate ? `${localFilterByStartDate} - ${localFilterByEndDate}` : "MM/DD/YYYY"} />
                        </div>
                        <div className='col-md-4 col-lg-3 col-xl-3 mb-5'>
                            <div className='d-flex align-items-end h-100 gap-3'>
                                <button className='btn btn-danger pull-right me-3' onClick={resetFilters}>Reset Filters</button>

                                <button className='btn btn-primary pull-right' onClick={applyFilters}>
                                    {isApplyingFilters ? <>
                                        <span>loading...</span>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    </> : "Apply Filters"}
                                </button>
                                <RefreshComponent onClick={handleRefresh} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className='table-responsive table-height'>
                                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                    <thead>
                                        <tr className='fw-bold text-muted'>
                                            {hiddenColumns.includes('reportId') && (
                                                <TableHeading
                                                    label={labelKey.reportId}
                                                    columnId='reportId'
                                                    className="ps-4"
                                                />
                                            )}
                                            {hiddenColumns.includes('user') && (
                                                <TableHeading
                                                    label={labelKey.user}
                                                    columnId='user'
                                                    className="ps-4"
                                                />
                                            )}
                                            {hiddenColumns.includes('expenseType') && (
                                                <TableHeading
                                                    label={labelKey.type}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='expenseType'
                                                />
                                            )}
                                            {hiddenColumns.includes('title') && (
                                                <TableHeading
                                                    label={labelKey.title}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='title'
                                                />
                                            )}
                                            {hiddenColumns.includes('expenseDetail') && (
                                                <TableHeading
                                                    label={labelKey.expenseDetail}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='expenseDetail'
                                                />
                                            )}
                                            {hiddenColumns.includes('amount') && (
                                                <TableHeading
                                                    label={labelKey.totalAmount}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='amount'
                                                />
                                            )}
                                            {hiddenColumns.includes('createdDate') && (
                                                <TableHeading
                                                    label={labelKey.date}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='createdDate'
                                                />
                                            )}
                                            {hiddenColumns.includes('expenseStatus') && (
                                                <TableHeading
                                                    label={labelKey.status}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='expenseStatus'
                                                />
                                            )}
                                            {hiddenColumns.includes('manager') && (
                                                <TableHeading
                                                    label={labelKey.assignTo}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='manager'
                                                />
                                            )}
                                            {hiddenColumns.includes('comment') && (
                                                <TableHeading
                                                    label={labelKey.comment}
                                                    columnId='comment'
                                                />
                                            )}
                                            {hiddenColumns.includes('action') && (
                                                <TableHeading
                                                    label={labelKey.action}
                                                    columnId='action'
                                                />
                                            )}
                                            <th className='text-end rounded-end pe-2 border-0'>
                                                {hiddenColumns.includes('actions') && (
                                                    <>
                                                        {labelKey.actions}
                                                    </>
                                                )}
                                                <TableSettingMenu>
                                                    <TextField
                                                        rightLabel={labelKey.reportId}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("reportId")}
                                                        checked={hiddenColumns.includes('reportId')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.user}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("user")}
                                                        checked={hiddenColumns.includes('user')}
                                                        fieldClass='mb-4'
                                                    />

                                                    <TextField
                                                        rightLabel={labelKey.type}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("expenseType")}
                                                        checked={hiddenColumns.includes('expenseType')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.title}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("title")}
                                                        checked={hiddenColumns.includes('title')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.totalAmount}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("amount")}
                                                        checked={hiddenColumns.includes('amount')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.date}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("createdDate")}
                                                        checked={hiddenColumns.includes('createdDate')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.status}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("expenseStatus")}
                                                        checked={hiddenColumns.includes('expenseStatus')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.assignTo}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("manager")}
                                                        checked={hiddenColumns.includes('manager')}
                                                        fieldClass='mb-4'
                                                    />

                                                    <TextField
                                                        rightLabel={labelKey.comment}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("comment")}
                                                        checked={hiddenColumns.includes('comment')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.actions}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("actions")}
                                                        checked={hiddenColumns.includes('actions')}
                                                        fieldClass='mb-4'
                                                    />
                                                </TableSettingMenu>
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!isLoading &&
                                            <>
                                                {data?.result?.data?.length > 0 ? (
                                                    <>
                                                        {data?.result?.data?.map((data: any, index: any) => (
                                                            <tr key={index}>
                                                                {/* <td className='ps-5'>{(page - 1) * pageSize + index + 1}</td> */}
                                                                {hiddenColumns.includes('reportId') && (
                                                                    <td className='ps-5'>{data?.trackingId}</td>
                                                                )}
                                                                {hiddenColumns.includes('user') && (
                                                                    <td>
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                                                                <div className="symbol-label fs-3 bg-light-info text-info text-capitalize">{data?.userAccount?.firstName?.charAt(0)}</div>
                                                                            </div>
                                                                            <div className="d-flex flex-column">
                                                                                <p className="text-gray-800 fw-bold text-hover-primary mb-1 text-capitalize">{data?.userAccount?.firstName} {data?.userAccount?.middleName} {data?.userAccount?.lastName}</p>
                                                                                <span className='text-lowercase'>{data?.userAccount?.email}</span>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                )}
                                                                {hiddenColumns.includes('expenseType') && (
                                                                    <td>{data?.expenseType?.title}</td>
                                                                )}
                                                                {hiddenColumns.includes('title') && (
                                                                    <td>{data?.title}</td>
                                                                )}
                                                                {hiddenColumns.includes('expenseDetail') && (
                                                                    <td>{data?.expenseDetail?.length > 60 ? `${data?.expenseDetail.substring(0, 40)}...` : data?.expenseDetail}</td>
                                                                )}
                                                                {hiddenColumns.includes('amount') && (
                                                                    <td><CurrencyComp amount={data?.amount} /></td>
                                                                )}
                                                                {hiddenColumns.includes('createdDate') && (
                                                                    <td><DateComp formattedDate={data.reportDate} /></td>
                                                                )}
                                                                {hiddenColumns.includes('expenseStatus') && (
                                                                    <td>
                                                                        {data?.expenseStatus.id === constraintConfig.expenseStatus.drafted ?
                                                                            <span className="badge badge-light-primary fs-7 fw-semibold">{data?.expenseStatus?.title}</span> :
                                                                            data?.expenseStatus.id === constraintConfig.expenseStatus.pendingForApproval ?
                                                                                <span className="badge badge-light-info fs-7 fw-semibold">{data?.expenseStatus?.title}</span> :
                                                                                // data?.expenseStatus.id === constraintConfig.expenseStatus.clarification ?
                                                                                //     <span className="badge badge-light-warning fs-7 fw-semibold">{data?.expenseStatus?.title}</span> :
                                                                                data?.expenseStatus.id === constraintConfig.expenseStatus.approved ?
                                                                                    <span className="badge badge-light-success fs-7 fw-semibold">{data?.expenseStatus?.title}</span> :
                                                                                    data?.expenseStatus.id === constraintConfig.expenseStatus.rejected ?
                                                                                        <span className="badge badge-light-danger fs-7 fw-semibold">{data?.expenseStatus?.title}</span> :
                                                                                        data?.expenseStatus.id === constraintConfig.expenseStatus.apApproved ?
                                                                                            <span className="badge badge-light-success fs-7 fw-semibold">{data?.expenseStatus?.title}</span> :
                                                                                            data?.expenseStatus.id === constraintConfig.expenseStatus.processing ?
                                                                                                <span className="badge badge-light-primary fs-7 fw-semibold">{data?.expenseStatus?.title}</span> :
                                                                                                ""
                                                                        }
                                                                    </td>
                                                                )}
                                                                {hiddenColumns.includes('manager') && (
                                                                    <td>
                                                                        {data?.manager?.firstName && <>
                                                                            {data?.expenseStatus?.title === "Pending for Approval" ? (
                                                                                <div className='d-flex align-items-center'>
                                                                                    <div className='symbol symbol-40px symbol-circle me-3'>
                                                                                        {data?.manager?.imageUrl ? <img src={data?.manager?.imageUrl} alt="" /> :
                                                                                            <div className="symbol-label fs-3 bg-light-info text-info text-capitalize">{data?.manager?.firstName?.charAt(0)}</div>
                                                                                        }
                                                                                    </div>
                                                                                    <div className='d-flex justify-content-start flex-column'>
                                                                                        <p className='text-capitalize mb-0'>
                                                                                            {data?.manager?.firstName}  {data?.manager?.middleName} {data?.manager?.lastName}
                                                                                        </p>
                                                                                        <span className='text-muted fw-semibold text-muted d-block fs-7 text-lowercase'>
                                                                                            {data?.manager?.email}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            ) : "Not Assigned"}
                                                                        </>}
                                                                    </td>
                                                                )}
                                                                {hiddenColumns.includes('comment') && (
                                                                    <td>{data?.comment && data?.comment.length > 20 ? `${data?.comment.substring(0, 20)}...` : data?.comment}</td>
                                                                )}
                                                                {hiddenColumns.includes('actions') && (
                                                                    <td className='text-end' >
                                                                        <div className='d-flex align-items-center justify-content-end gap-2' >
                                                                            {roleID === constraintConfig.roleID.role2 && data?.manager?.orgUserID ?
                                                                                <>
                                                                                    <Tooltip id="impersonate" place="bottom" />
                                                                                    <span className="badge badge-light-primary fs-7 fw-semibold"
                                                                                        onClick={() => {
                                                                                            setImpersonateData(data?.manager);
                                                                                            impersonateHandler();
                                                                                        }}
                                                                                    >{labelKey.impersonateUser}</span>
                                                                                </> : null}
                                                                            <DownloadReport data={data} />
                                                                            <div
                                                                                data-tooltip-id={labelKey.updateExpense} data-tooltip-content={labelKey.updateExpense}
                                                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                                                                onClick={() => {
                                                                                    expenseItemByIdTrigger({ ExpenseId: data?.expenseId });
                                                                                    setExpenseDetailData(data);
                                                                                    expenseDetailHandler();
                                                                                }}
                                                                            >
                                                                                <KTSVG
                                                                                    path='/media/icons/duotune/arrows/arr064.svg'
                                                                                    className='svg-icon-3'
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                )}
                                                            </tr>
                                                        ))}
                                                    </>
                                                ) :
                                                    (
                                                        <tr>
                                                            <td colSpan={15}>
                                                                <NoRecordFound />
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </>
                                        }
                                        {isLoading && (
                                            <tr>
                                                <td colSpan={15} className="text-center">
                                                    <LoadingComponent />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <Pagination
                            totalResult={data?.result?.totalRecords === 0 ? 0 : ((data?.result?.pageNumber - 1) * data?.result?.pageSize) + 1}
                            toResult={data?.result?.totalRecords === 0 ? 0 : Math.min(data?.result?.pageNumber * data?.result?.pageSize, data?.result?.totalRecords)}
                            ofResult={data?.result?.totalRecords}
                            onChange={handlePageSizeChange}
                            pageSize={pageSize}
                            onPageChange={handlePageClick}
                            pageCount={data?.result?.totalPages || 0}
                            currentPage={page}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ExpenseSearch